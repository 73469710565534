import styled from 'styled-components'

type Props = {
  prefix?: string
  suffix: string
  dynamicSuffix?: string
  subline?: string
  glue?: string
}

const Suffix = styled.div`
  @keyframes mark {
    0% {
      -webkit-clip-path: inset(0 100% 0 0);
      clip-path: inset(0 100% 0 0);
    }
    100% {
      -webkit-clip-path: inset(0 0 0 0);
      clip-path: inset(0 0 0 0);
    }
  }

  position: relative;
  z-index: 1;
`

const HeadlineSplit: React.FC<Props> = ({ prefix, suffix, dynamicSuffix, subline, glue }) => {
  return (
    <div>
      <div className="flex gap-2 items-center">
        {!!prefix && <div className="text-3xl leading-8 font-light text-black">{prefix}</div>}
        <Suffix className="text-3xl leading-8 font-italic-bold text-black">{suffix}</Suffix>
        {!!dynamicSuffix && <div className="text-3xl leading-8 font-italic text-black">{` ${glue || '-'} ${dynamicSuffix}`}</div>}
      </div>
      {subline && <div className="mt-1 text-black">{subline}</div>}
    </div>
  )
}

export default HeadlineSplit
