import { useState } from 'react'
import Items from '../Items/Items'
import { Bars3Icon } from '@heroicons/react/24/solid'
import SlideIn from '../../../../SlideIn/SlideIn'

const Toggle: React.FC<{ small: boolean }> = ({ small }) => {
  const [open, setOpen] = useState<boolean>(false)

  const toggle = () => {
    setOpen(!open)
  }

  return (
    <SlideIn
      Trigger={
        <div className="cursor-pointer" onClick={toggle}>
          <div className="w-10 h-10">
            <Bars3Icon className="fill-black" />
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-2">
        <Items mobile onClick={toggle} />
      </div>
    </SlideIn>
  )
}
export default Toggle
