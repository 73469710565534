import { useSelector } from 'react-redux'
import DNDList from '../../../../UI/DNDList/DNDList'
import props from '../../../../../redux/props'
import RemoveConfirmation from '../../../../UI/RemoveConfirmation/RemoveConfirmation'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Bundle } from '../../../../../types/Bundle'
import useLoadProducts from '../../../../hooks/useLoadProducts'
import { useState } from 'react'
import Modal from '../../../../UI/Modal/Modal'

type Props = {
  bundle: Bundle
  setProductUUIDList: (setProductUUIDList: string[]) => void
}

const ProductManager: React.FC<Props> = ({ bundle, setProductUUIDList }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [products] = useLoadProducts()

  const [productSelectionOpen, setProductSelectionOpen] = useState<boolean>(false)

  const remove = (uuid: string) => async () => {
    const currentProductUUIDList = Array.isArray(bundle.productUUIDList) ? bundle.productUUIDList : []
    setProductUUIDList(currentProductUUIDList.filter((u) => u !== uuid))
  }

  const getProduct = (uuid: string) => {
    return products.find((product) => product.uuid === uuid)
  }

  const toggleProductSelectionOpen = () => setProductSelectionOpen(!productSelectionOpen)

  const add = (uuid: string) => () => {
    const currentProductUUIDList = Array.isArray(bundle.productUUIDList) ? bundle.productUUIDList : []
    setProductUUIDList([...currentProductUUIDList, uuid])
    toggleProductSelectionOpen()
  }

  if (!products) return <></>
  return (
    <>
      <div className="w-full">
        <div className="text-gray text-sm">{t.bundle.productManager.title}</div>
        <DNDList
          orderUpdateCallback={setProductUUIDList}
          items={bundle.productUUIDList
            .filter((p) => !!getProduct(p))
            .map((uuid, index: number) => {
              return {
                id: uuid,
                data: uuid,
                content: (
                  <div
                    key={uuid}
                    className="flex flex-row items-start justify-between px-3 border-b border-graySecondary border-opacity-20 py-5 gap-3 bg-white"
                  >
                    {/* <div className="flex flex-row gap-3">
                  <div className="w-8 aspect-square bg-black flex items-center justify-center text-white">{index + 1}</div>
                  <Image key={imageKey} src={imageKey} className="w-20" />
                </div> */}
                    {getProduct(uuid).name}
                    <RemoveConfirmation confirmationCallback={remove(uuid)}>
                      <TrashIcon className="fill-black hover:fill-red transition-all w-5 cursor-pointer" />
                    </RemoveConfirmation>
                  </div>
                ),
              }
            })}
        />
        <div className="w-fit p-1 bg-black ml-auto cursor-pointer mt-3" onClick={toggleProductSelectionOpen}>
          <PlusIcon className="w-4 h-4 fill-white" />
        </div>
      </div>
      <Modal
        noForm
        show={productSelectionOpen}
        cancelButtonText={t.generic.cancel}
        onClose={toggleProductSelectionOpen}
        title={t.bundle.productManager.selection.title}
      >
        {products
          .filter((product) => !bundle.productUUIDList.includes(product.uuid))
          .map((product) => (
            <div className="p-2 border-b border-graySecondary last:border-none hover:bg-lightGray cursor-pointer" onClick={add(product.uuid)}>
              {product.name}
            </div>
          ))}
      </Modal>
    </>
  )
}

export default ProductManager
