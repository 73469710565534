import { useSelector } from 'react-redux'
import props from '../../redux/props'
import { PricingType, Product } from '../../types/Product'
import { formatEUR } from '../../utility'
import { isNumber } from 'lodash'

type Props = { product: Product; packageUUID: string; setPackageUUID: (uuid: string) => void }

const Price: React.FC<Props> = ({ product, packageUUID, setPackageUUID }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getAdditionalPrice = () => {
    return product.price.additional.map((p, i) => (
      <div key={i} className="flex flex-col md:flex-row md:items-center md:gap-2">
        <div>{`${p.text}: `}</div>
        <div>{formatEUR(p.price)}</div>
      </div>
    ))
  }

  return (
    <div className="mt-10">
      {product.price.pricingType === PricingType.SINGLE && (
        <div className="font-bold">
          {t.product.priceFrom}
          <span className="font-bold text-xl pl-1">{formatEUR(product.price.startingPrice)}</span>
        </div>
      )}
      {product.price.pricingType === PricingType.PACKAGES && (
        <div className="flex flex-col gap-5">
          {product.price.packages.map((p, i) => (
            <div
              key={i}
              onClick={() => setPackageUUID(p.uuid)}
              className={`cursor-pointer p-3 gap-2 border-gold rounded-lg ${packageUUID === p.uuid ? 'border-4' : 'border-2'}`}
            >
              <div className="flex justify-between items-center">
                <div className="text-lg font-bold">{p.name}</div>
                <div className="font-bold">{formatEUR(p.price)}</div>
              </div>
              <div className="text-sm whitespace-pre-wrap">{p.text}</div>
            </div>
          ))}
        </div>
      )}
      {isNumber(product.price.deposit) && product.price.deposit > 0 && (
        <div className="mt-5">
          {t.product.depositPrefix}
          {formatEUR(product.price.deposit)}
        </div>
      )}
      {Array.isArray(product.price.additional) && product.price.additional.length > 0 && (
        <div className="flex flex-col mt-3">
          <div className="w-full">
            <div className="flex flex-col gap-1">{getAdditionalPrice()}</div>
          </div>
        </div>
      )}
      {!!product.price.hint && (
        <div className="font-bold mt-3 text-red">
          {`${t.product.priceManager.hint}: `}
          {product.price.hint}
        </div>
      )}
    </div>
  )
}

export default Price
