import { Route, Routes } from 'react-router-dom'
import List from './List/List'
import Update from './Update/Update'

const Product = () => {
  return (
    <Routes>
      <Route path={'/'} element={<List />} />
      <Route path={'/:productUUID'} element={<Update />} />
    </Routes>
  )
}

export default Product
