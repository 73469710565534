import { useSelector } from 'react-redux'
import props from '../../redux/props'
import Container from '../UI/Container/Container'
import { DevicePhoneMobileIcon, EnvelopeIcon, MapPinIcon, UserIcon } from '@heroicons/react/24/outline'
import robin from '../../assets/image/qr/Robin.jpg'
import marco from '../../assets/image/qr/Marco.jpg'
import niclas from '../../assets/image/qr/Niclas.jpg'

const Contact = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const qrImages = [robin, marco, niclas]

  return (
    <div className="py-32">
      <Container>
        <div className="text-gold text-xl leading-6 text-center">{t.contact.subline}</div>
        <div className="font-bold text-4xl text-center">{t.contact.headline}</div>
        <div className="mt-5 flex flex-col md:flex-row gap-16 md:gap-0 w-full">
          {t.contact.locations.map((location, i) => (
            <div className="flex-1 items-center flex flex-col" key={i}>
              <div className="text-lg flex gap-1 items-center">
                <UserIcon className="w-6 inline-block stroke-gold" />
                {location.contact}
              </div>
              <a href={`tel:${location.phone}`} className="text-lg flex gap-1 items-center">
                <DevicePhoneMobileIcon className="w-6 inline-block stroke-gold" />
                {location.phone}
              </a>
              <a href={`mailto:${location.mail}`} className="text-lg flex gap-1 items-center">
                <EnvelopeIcon className="w-6 inline-block stroke-gold" />
                {location.mail}
              </a>
              <a
                href={`https://www.google.com/maps/search/${location.city}`}
                className="text-lg flex gap-1 items-center"
                target="_blank"
                rel="noreferrer"
              >
                <MapPinIcon className="w-6 inline-block stroke-gold" />
                {location.city}
              </a>
              <a href={location.qrLink} className="mt-3">
                <img src={qrImages[i]} alt="qr code" className="w-32" />
              </a>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Contact
