import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { Product } from '../../../../types/Product'

type Props = { data: Product; pullList: () => void }

const Item: React.FC<Props> = ({ data }) => {
  return (
    <div className="cursor-pointer flex w-full items-center gap-2">
      <a className="w-full block hover:underline" href={`/admin/product/${data.uuid}`}>
        {data.name}
      </a>
      <div className="w-full block">
        {data.imageKeyList.length > 0 ? <CheckCircleIcon className="w-5 fill-green" /> : <XCircleIcon className="w-5 fill-red" />}
      </div>
    </div>
  )
}

export default Item
