import { Product } from '../../../../types/Product'
import Small from '../Card/Small'
import useLoadProducts from '../../../hooks/useLoadProducts'

const Tripple = () => {
  const [products] = useLoadProducts()

  const getRandomProducts = () => {
    const randomProducts: Product[] = []
    const randomProductUUIDs: string[] = []
    while (randomProducts.length < 3) {
      const randomProduct = products[Math.floor(Math.random() * products.length)]
      if (randomProduct.imageKeyList.length > 0 && !randomProductUUIDs.includes(randomProduct.uuid)) {
        randomProductUUIDs.push(randomProduct.uuid)
        randomProducts.push(randomProduct)
      }
    }
    return randomProducts
  }

  if (!products) return <></>
  return (
    <div className="flex flex-col md:flex-row gap-32">
      {getRandomProducts().map((product) => (
        <div className="flex-1 flex justify-center" key={product.uuid}>
          <Small product={product} />
        </div>
      ))}
    </div>
  )
}

export default Tripple
