export type AdditionalPriceInfo = {
  text: string
  price: number
}

export enum PricingType {
  SINGLE = 'SINGLE',
  PACKAGES = 'PACKAGES',
}

export type Package = {
  uuid: string
  name: string
  text: string
  price: number
}

export type Price = {
  pricingType: PricingType
  startingPrice?: number
  packages?: Package[]
  deposit?: number
  additional?: AdditionalPriceInfo[]
  hint?: string
}

export type Product = {
  uuid?: string
  name?: string
  subline?: string
  imageKeyList?: string[]
  categoryUUID?: string
  description?: string
  price?: Price
  technicalSheetKey?: string
  operatingInstructionsKey?: string

  // dynamic properties
  technicalSheetURL?: string
  operatingInstructionsURL?: string
}
