import { useEffect, useState } from 'react'

export enum Breakpoint {
  SM = 768,
  MD = 992,
  LG = 1320,
}

const useIsBreakpoint = (breakpoint: Breakpoint) => {
  const [isBreakpoint, setIsBreakpoint] = useState(false)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < breakpoint) {
        setIsBreakpoint(true)
      } else {
        setIsBreakpoint(false)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [breakpoint])

  return isBreakpoint
}

export default useIsBreakpoint
