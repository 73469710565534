import { useDispatch, useSelector } from 'react-redux'
import { getUUID } from '../../../../../../utility'
import Default from './Item/Default'
import { useNavigate, useParams } from 'react-router-dom'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import props from '../../../../../../redux/props'
import Cookies from 'universal-cookie'
import { setLocal } from '../../../../../../redux/action/local'
import { NavigationItem } from '../../types'
import Button from '../../../../Button/Button'
// import instagram from '../../../../../../assets/image/socialmedia/instagram_white.png'
import useLoadCategories from '../../../../../hooks/useLoadCategories'
import Cart from '../../../../../Cart/Cart'
import instagram from '../../../../../../assets/image/instagram.svg'
import youtube from '../../../../../../assets/image/youtube.svg'

type Props = {
  mobile?: boolean
  onClick?: () => void
  small?: boolean
}

const Items: React.FC<Props> = ({ mobile = false, onClick = null, small = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLoggedIn = () => !!accountLink
  const [categories] = useLoadCategories()
  const { categoryUUID } = useParams()

  const isSelected = (c) => {
    return c.uuid === categoryUUID
  }

  const logout = () => {
    if (onClick) {
      onClick()
    }
    const cookies = new Cookies()
    cookies.remove('token', { path: '/' })
    dispatch(setLocal(props.ACCOUNT_LINK, null))
    dispatch(setLocal(props.TOKEN, null))
    navigate('/')
  }

  return (
    <>
      {isLoggedIn() ? (
        <>
          {t.header.navigation[accountLink.level].map((item: NavigationItem) => (
            <Default onClick={onClick} key={getUUID()} data={item} small={small} />
          ))}
          <Button text={t.generic.logout} onClick={logout} />
        </>
      ) : (
        <>
          {Array.isArray(categories) &&
            categories.map((c) => (
              <Default
                mobile={mobile}
                onClick={onClick}
                key={getUUID()}
                isSelected={isSelected(c)}
                data={{
                  name: c.name,
                  link: `/kategorie/${c.uuid}`,
                  items: [],
                }}
                small={small}
              />
            ))}
          {/* {t.header.navigation.default.map((item: NavigationItem) => (
            <Default mobile={mobile} onClick={onClick} key={getUUID()} data={item} small={small} />
          ))} */}
          <a href="https://www.instagram.com/event.paradies/" target="_blank" rel="noreferrer">
            <img src={instagram} alt="instagam" className="w-6" />
          </a>
          <a href="https://www.youtube.com/@eventparadies" target="_blank" rel="noreferrer">
            <img src={youtube} alt="youtube" className="w-7" />
          </a>
          <div className="hidden md:block">
            <Cart />
          </div>
        </>
      )}
    </>
  )
}

export default Items
