import { Value } from '../UI/DateRangePicker/DateRangePicker'

export enum PreferredContact {
  PHONE = 'PHONE',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
  MAIL = 'MAIL',
}

export enum DeliveryMethod {
  DELIVERY = 'DELIVERY',
  PICKUP = 'PICKUP',
}

export type PersonalData = {
  firstname: string
  lastname: string
  streetAndHousenumber: string
  postcode: string
  city: string
  mail: string
  phone: string
  text?: string
  dateRange: Value
  preferredContact: PreferredContact
  deliveryMethod: DeliveryMethod
}

export type ProductCartItem = {
  uuid: string
  packageUUID?: string
}

export type Cart = {
  uuid?: string
  userUUID: string
  productList: ProductCartItem[]
}
