import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import LargeContainer from '../../../UI/Container/LargeContainer'
import { Bundle } from '../../../../types/Bundle'
import SimpleList from '../../../UI/SimpleList/SimpleList'
import Item from './Item'
import Create from '../Create/Create'
import HeadlineSplit from '../../../UI/Headline/Split'
import useLoadBundles from '../../../hooks/useLoadBundles'

const List = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [bundles, pull] = useLoadBundles()

  const filter = (search: string) => (item: Bundle) => {
    return item.name.toLowerCase().includes(search.toLowerCase())
  }

  const Header = () => (
    <div className="cursor-pointer flex w-full items-center gap-2">
      <div className="w-full font-bold">{t.bundle.attributes.name}</div>
    </div>
  )

  return (
    <LargeContainer>
      <div className="flex flex-col gap-1 pt-2 pb-10 mt-10">
        <HeadlineSplit suffix={t.bundle.list.titleSplit.suffix} subline={t.bundle.list.titleSplit.subline} />
        <SimpleList data={bundles} filter={filter} Component={Item} pull={pull} Upsert={Create} Header={Header} />
      </div>
    </LargeContainer>
  )
}

export default List
