import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { getProductLink, shortenDescription } from '../../../../utility'
import { Product } from '../../../../types/Product'
import Image from '../../Image/Image'

type Props = {
  product: Product
}

const Small: React.FC<Props> = ({ product }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="aspect-square w-full relative rounded-lg">
      <div className="absolute z-10 w-full h-full bg-gradient-to-t from-white from-0% via-0%"></div>
      <Image src={product.imageKeyList[0]} className="w-full h-full object-cover rounded-lg" />
      <div className="absolute w-full bottom-10 z-20">
        <div className="text-center font-bold text-gold text-xl">{product.name}</div>
        <div className="text-center">{shortenDescription(product)}</div>
      </div>
      <a
        href={getProductLink(product)}
        className="z-20 rounded-lg bg-white hover:scale-105 transition-all text-black font-bold shadow-md w-fit whitespace-nowrap py-3 px-10 absolute bottom-0 translate-y-1/2 left-1/2 transform -translate-x-1/2 cursor-pointer"
      >
        {t.UI.card.small.cta}
      </a>
    </div>
  )
}

export default Small
