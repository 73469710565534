import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Item as ItemType } from './types'
import Item from './Item'

type Props = {
  items: ItemType[]
  invert?: boolean
}

const Column: React.FC<Props> = ({ items, invert = false }) => {
  return (
    <div className="overflow-hidden">
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item) => (
          <Item key={item.id} item={item} invert={invert} />
        ))}
      </SortableContext>
    </div>
  )
}

export default Column
