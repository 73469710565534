import { useEffect, useState } from 'react'
import Input from '../../../UI/Input/Input'
import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import DirectFileUpload, { OnCallbackFile, UPLOAD_TYPES } from '../../../UI/DirectFileUpload/DirectFileUpload'
import { imageMaxFileSizeInBytes, imageMimeTypes } from '../../../../utility'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type as ToastType } from '../../../hooks/useToast'
import Button, { Type } from '../../../UI/Button/Button'
import { useParams } from 'react-router-dom'
import Container from '../../../UI/Container/Container'
import Form from '../../../UI/Form/Form'
import RemoveConfirmation from '../../../UI/RemoveConfirmation/RemoveConfirmation'
import ProductManager from './ProductManager/ProductManager'
import { Bundle } from '../../../../types/Bundle'
import Image from '../../../UI/Image/Image'
import styled from 'styled-components'
import { TrashIcon } from '@heroicons/react/24/solid'
import Textarea from '../../../UI/Textarea/Textarea'

const ItemWrapper = styled.div`
  width: calc(50% - 10px);
`

const Update: React.FC<{}> = () => {
  const { bundleUUID } = useParams()
  const t = useSelector((s) => s[props.TRANSLATION])
  const bundleFeature = crud.use(props.BUNDLE)
  const toast = useToast()
  const [bundle, setBundle] = useState<Bundle | null>(null)

  const set = (key: keyof Bundle) => (value: any) => setBundle({ ...bundle, [key]: value })

  const onImage = (file: OnCallbackFile) => {
    if (file && file.key) {
      setBundle({ ...bundle, imageKey: file.key })
    }
  }

  const save = async (): Promise<boolean> => {
    const updatedBundle = await bundleFeature.upsert(bundle)
    if (updatedBundle) {
      toast(t.bundle.upsert.success, ToastType.SUCCESS)
      return true
    } else {
      toast(t.bundle.upsert.error)
      return false
    }
  }

  const remove = async () => {
    const status = await bundleFeature.remove({ uuid: bundle.uuid })
    if (status) {
      window.location.href = '/admin/bundle'
    }
  }

  useEffect(() => {
    const getBundle = async () => {
      const bundle = await bundleFeature.get({ uuid: bundleUUID })
      setBundle(bundle)
    }
    if (!bundle) {
      getBundle()
    }
  }, [bundleUUID, bundle, bundleFeature])

  if (!bundle) return <></>
  return (
    <div className="pb-20 pt-10">
      <Container>
        <Form onSubmit={save}>
          <div className="flex flex-row gap-[20px] flex-wrap">
            <ItemWrapper>
              <div className="text-xl font-bold">{bundle.name}</div>
            </ItemWrapper>
            <ItemWrapper>
              <div className="flex justify-end">
                <RemoveConfirmation itemName={bundle.name} confirmationCallback={remove}>
                  <TrashIcon className="fill-black hover:fill-red w-5 cursor-pointer" />
                </RemoveConfirmation>
              </div>
            </ItemWrapper>
            <Input label={t.bundle.attributes.name} value={bundle.name} onChange={set('name')} required />
            <Textarea label={t.bundle.attributes.description} value={bundle.description} onChange={set('description')} />
            <ProductManager bundle={bundle} setProductUUIDList={set('productUUIDList')} />
            <DirectFileUpload
              uploadType={UPLOAD_TYPES.BUNDLE_IMAGE}
              allowedMimeTypes={imageMimeTypes}
              maxFileSizeInBytes={imageMaxFileSizeInBytes}
              onFilesCallback={onImage}
              label={t.bundle.upsert.coverUploadLabel}
            />
            {bundle.imageKey && (
              <div>
                <Image src={bundle.imageKey} className="w-full h-40" />
              </div>
            )}
            <div className="w-full">
              <Button className="ml-auto" type={Type.SUBMIT} text={t.generic.save} />
            </div>
          </div>
        </Form>
      </Container>
    </div>
  )
}

export default Update
