import { Product } from '../../types/Product'
import { Cart } from './types'

export const getProduct = (products: Product[], productUUID: string): Product => {
  return products.find((p) => p.uuid === productUUID)
}

export const getTotal = (cart: Cart, products: Product[]) => {
  return cart.productList.reduce((acc, p) => {
    const product = getProduct(products, p.uuid)
    if (!product) return acc
    if (p.packageUUID) {
      const selectedPackage = product.price.packages.find((p2) => p2.uuid === p.packageUUID)
      if (selectedPackage) {
        return acc + selectedPackage.price
      }
    }
    return acc + product.price.startingPrice
  }, 0)
}
