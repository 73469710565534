import Datepicker from 'react-tailwindcss-datepicker'

export type Value = {
  startDate: Date | null
  endDate: Date | null
}

type Props = {
  id?: string
  label?: string
  placeholder?: string
  className?: string
  invert?: boolean
  required?: boolean
  value: Value
  onChange: (value: Value) => void
}

const DateRangePicker: React.FC<Props> = ({ id, required, label, placeholder, className = '', invert = false, value, onChange }) => {
  return (
    <div className={`w-full ${className}`}>
      {label && (
        <label htmlFor={id} className={invert ? 'text-white text-sm' : 'text-gray text-sm'}>
          {typeof label === 'string' ? label : placeholder}
        </label>
      )}
      <Datepicker
        minDate={new Date()}
        primaryColor={'yellow'}
        value={value}
        onChange={(newValue) => onChange(newValue)}
        inputClassName="border rounded-md border-gold placeholder-gray w-full py-1 px-1 bg-white text-black focus:outline-none focus:shadow-outline"
        displayFormat="DD.MM.YYYY"
        i18n="de"
        required={required}
      />
    </div>
  )
}

export default DateRangePicker
