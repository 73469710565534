import { useEffect, useState } from 'react'
import Input from '../../../UI/Input/Input'
import { Product } from '../../../../types/Product'
import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type as ToastType } from '../../../hooks/useToast'
import Button, { Type } from '../../../UI/Button/Button'
import { useParams } from 'react-router-dom'
import { TrashIcon } from '@heroicons/react/24/solid'
import CategorySelector from '../../../UI/CategorySelector/CategorySelector'
import Container from '../../../UI/Container/Container'
import Form from '../../../UI/Form/Form'
import RemoveConfirmation from '../../../UI/RemoveConfirmation/RemoveConfirmation'
import styled from 'styled-components'
import ImageManager from './ImageManager/ImageManager'
import Textarea from '../../../UI/Textarea/Textarea'
import PriceManager from './PriceManager/PriceManager'
import FileUpload from './FileUpload/FileUpload'

export const ItemWrapper = styled.div`
  width: calc(50% - 10px);
`

const Update: React.FC<{}> = () => {
  const { productUUID } = useParams()
  const t = useSelector((s) => s[props.TRANSLATION])
  const productFeature = crud.use(props.PRODUCT)
  const toast = useToast()
  const [product, setProduct] = useState<Product | null>(null)

  const set = (key: keyof Product) => (value: any) => setProduct({ ...product, [key]: value })

  const save = async (): Promise<boolean> => {
    const updatedProduct = await productFeature.upsert(product)
    if (updatedProduct) {
      toast(t.product.upsert.success, ToastType.SUCCESS)
      return true
    } else {
      toast(t.product.upsert.error)
      return false
    }
  }

  const remove = async () => {
    const status = await productFeature.remove({ uuid: product.uuid })
    if (status) {
      window.location.href = '/admin/product'
    }
  }

  useEffect(() => {
    const getProduct = async () => {
      const product = await productFeature.get({ uuid: productUUID })
      setProduct(product)
    }
    if (!product) {
      getProduct()
    }
  }, [productUUID, product, productFeature])

  if (!product) return <></>
  return (
    <div className="pb-20 pt-10">
      <Container>
        <Form onSubmit={save}>
          <div className="flex flex-row gap-[20px] flex-wrap">
            <ItemWrapper>
              <div className="text-2xl font-bold">{product.name}</div>
            </ItemWrapper>
            <ItemWrapper>
              <div className="flex justify-end">
                <RemoveConfirmation itemName={product.name} confirmationCallback={remove}>
                  <TrashIcon className="fill-black hover:fill-red w-5 cursor-pointer" />
                </RemoveConfirmation>
              </div>
            </ItemWrapper>
            <div className="w-full font-bold text-xl mt-5">{t.product.propertyManager.title}</div>
            <div className="flex flex-col gap-3 border border-gold rounded-lg p-3 shadow-sm w-full">
              <ItemWrapper>
                <Input label={t.product.attributes.name} value={product.name} onChange={set('name')} required />
              </ItemWrapper>
              <ItemWrapper>
                <Input label={t.product.attributes.subline} value={product.subline} onChange={set('subline')} />
              </ItemWrapper>
              <CategorySelector value={product.categoryUUID} onChange={set('categoryUUID')} />
              <Textarea rows={10} label={t.product.attributes.description} value={product.description} onChange={set('description')} />
            </div>
            <PriceManager value={product.price} onChange={set('price')} />
            <ImageManager product={product} setImageKeyList={set('imageKeyList')} />
            <div className="font-bold text-xl mt-5">{t.product.documentManager.title}</div>
            <FileUpload label={t.product.upsert.technicalSheetUploadLabel} value={product.technicalSheetKey} onChange={set('technicalSheetKey')} />
            <FileUpload
              label={t.product.upsert.operatingInstructionsUploadLabel}
              value={product.operatingInstructionsKey}
              onChange={set('operatingInstructionsKey')}
            />
            <Button className="ml-auto" type={Type.SUBMIT} text={t.generic.save} />
          </div>
        </Form>
      </Container>
    </div>
  )
}

export default Update
