import { s3PublicStoragePrefix } from '../../../utility'

type Props = {
  src: string
  round?: boolean
  addPrefix?: boolean
  className?: string
}

const Image: React.FC<Props> = ({ src, round, addPrefix = true, className = '' }) => {
  const getSrc = () => {
    if (addPrefix) {
      return `${s3PublicStoragePrefix}${src}`
    }
    return src
  }

  const classNameString = `object-contain ${round && 'rounded-full aspect-square'} ${className}`

  if (!src) return <div className={classNameString}></div>
  return <img src={getSrc()} className={classNameString} alt="" />
}

export default Image
