import { PlusIcon } from '@heroicons/react/24/solid'
import { TrashIcon } from '@heroicons/react/24/outline'
import { AdditionalPriceInfo } from '../../../../../types/Product'
import Input from '../../../../UI/Input/Input'
import Currency from '../../../../UI/Input/Currency'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'

type Props = {
  value: AdditionalPriceInfo[]
  onChange: (value: AdditionalPriceInfo[]) => void
}

const Additional: React.FC<Props> = ({ value, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const updateAdditional = (index: number, key: keyof AdditionalPriceInfo) => (newValue: string) => {
    const updatedProperties = value.map((p, i) => (i === index ? { ...p, [key]: newValue } : p))
    onChange(updatedProperties)
  }

  const addAdditional = () => {
    onChange([...value, { text: '', price: 0 }])
  }

  return (
    <div className="w-full">
      <label className={'text-gray text-sm'}>{t.product.priceManager.additional.title}</label>
      <div className="flex flex-col gap-1">
        {value.map((property, index) => (
          <div className="flex gap-[20px] items-center p-3 bg-lightGray rounded-lg shadow-sm" key={`prop-${index}`}>
            <Input placeholder={t.product.priceManager.additional.text} value={property.text} onChange={updateAdditional(index, 'text')} />
            <Currency placeholder={t.product.priceManager.additional.price} value={property.price} onChange={updateAdditional(index, 'price')} />
            <TrashIcon
              className="w-6 shrink-0 stroke-black hover:stroke-red cursor-pointer"
              onClick={() => onChange(value.filter((_, i) => i !== index))}
            />
          </div>
        ))}
      </div>
      <div className="w-fit p-1 bg-gold ml-auto cursor-pointer mt-1" onClick={addAdditional}>
        <PlusIcon className="w-4 h-4" />
      </div>
    </div>
  )
}

export default Additional
