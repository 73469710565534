import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import props from '../../../redux/props'

const BundleInfo = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="bg-gold py-20 mb-10 relative shadow-sm">
      <Container>
        <div className="text-white text-xl leading-6 text-center">{t.home.bundleInfo.subline}</div>
        <div className="text-white font-bold text-4xl text-center">{t.home.bundleInfo.headline}</div>
        <div className="text-white mt-5 mx-auto md:w-1/2 text-center text-lg">{t.home.bundleInfo.text}</div>
      </Container>
      <a
        href="/bundles"
        className="bg-white rounded-lg hover:scale-105 transition-all whitespace-nowrap font-bold text-black shadow-md w-fit py-3 px-10 absolute bottom-0 translate-y-1/2 left-1/2 transform -translate-x-1/2 cursor-pointer"
      >
        {t.home.bundleInfo.ctaText}
      </a>
    </div>
  )
}

export default BundleInfo
