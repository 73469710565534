import { useSelector } from 'react-redux'
import props from '../../redux/props'
import { Product } from '../../types/Product'
import useCart from './useCart'
import { ShoppingCartIcon, TrashIcon } from '@heroicons/react/24/solid'
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline'

type Props = {
  product: Product
  packageUUID?: string
  packageRequired: boolean
}

const Add: React.FC<Props> = ({ product, packageUUID, packageRequired }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { add, isIncluded, remove } = useCart()

  const addToCart = () => {
    add(product, packageUUID)
  }

  const removeFromCart = () => {
    remove(product.uuid)
  }

  if (!isIncluded) return null
  if (isIncluded(product)) {
    return (
      <div className="mt-10">
        <div
          className="cursor-pointer rounded-lg flex items-center justify-center gap-3 text-center py-2 bg-gold text-lg w-full group hover:bg-white border border-gold transition-all"
          onClick={removeFromCart}
        >
          <TrashIcon className="w-5 fill-white group-hover:fill-gold" />
          <div className="text-white group-hover:text-gold">{t.product.cart.removeCTA}</div>
        </div>
      </div>
    )
  }
  if (packageRequired && !packageUUID) {
    return (
      <div className="mt-10">
        <div className="cursor-not-allowed rounded-lg flex items-center justify-center gap-3 text-center py-2 bg-gold text-lg w-full border-gold transition-all">
          <CursorArrowRaysIcon className="w-5 stroke-white" />
          <div className="text-white group-hover:text-gold">{t.product.cart.selectPackageFirst}</div>
        </div>
      </div>
    )
  }
  return (
    <div className="mt-10">
      <div
        className="cursor-pointer rounded-lg flex items-center justify-center gap-3 text-center py-2 bg-gold text-lg w-full group hover:bg-white border border-gold transition-all"
        onClick={addToCart}
      >
        <ShoppingCartIcon className="w-5 fill-white group-hover:fill-gold" />
        <div className="text-white group-hover:text-gold">{t.product.cart.addCTA}</div>
      </div>
    </div>
  )
}

export default Add
