import { useSelector } from 'react-redux'
import { formatEUR, getProductLink } from '../../utility'
import props from '../../redux/props'
import styled from 'styled-components'
import Button from '../UI/Button/Button'
import { PricingType, Product } from '../../types/Product'
import Image from '../UI/Image/Image'

type Props = {
  product: Product
  fullWidth?: boolean
}

const CardStyled = styled.div`
  width: calc(100% / 3 - 75px * 2 / 3);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

const Card: React.FC<Props> = ({ product, fullWidth = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getPrice = (product: Product) => {
    if (product.price.pricingType === PricingType.PACKAGES) {
      return formatEUR(product.price.packages[0].price)
    }
    return formatEUR(product.price.startingPrice)
  }

  const Body = (
    <>
      <a href={getProductLink(product)} className="w-full rounded-lg aspect-square block group relative overflow-hidden shadow-md">
        <Image src={product.imageKeyList[0]} className="w-full h-full object-cover" />
        {/* {Array.isArray(product.properties) && (
          <div className="absolute w-full h-full top-0 left-0 opacity-0 group-hover:opacity-100 group-hover:translate-y-0 translate-y-full transition-all bg-gradient-to-t from-black from-30% via-0% flex flex-col justify-end p-5">
            {product.properties.slice(0, 3).map((property, i) => (
              <div key={i} className="flex flex-row justify-between">
                <div className="text-white">{property.name}</div>
                <div className="text-white">{property.value}</div>
              </div>
            ))}
          </div>
        )} */}
      </a>
      <div className="ml-3">
        <div className="font-bold text-lg leading-5 mt-4">{product.name}</div>
        <div className="text-gold mt-2 font-bold">
          {t.product.priceFrom}
          {getPrice(product)}
        </div>
        <Button className="rounded-lg mt-2" small href={getProductLink(product)} text={t.product.show} />
      </div>
    </>
  )

  if (fullWidth) {
    return <div>{Body}</div>
  }
  return <CardStyled>{Body}</CardStyled>
}

export default Card
