import { Bundle } from '../../../../types/Bundle'

type Props = { data: Bundle; pullList: () => void }

const Item: React.FC<Props> = ({ data }) => {
  return (
    <div className="cursor-pointer flex w-full items-center gap-2">
      <a className="w-full block hover:underline" href={`/admin/bundle/${data.uuid}`}>
        {data.name}
      </a>
    </div>
  )
}

export default Item
