export const translation = {
  projectName: 'Event-Paradies',

  header: {
    navigation: {
      default: [
        {
          name: 'Fotoboxen',
          link: '/produkt/fotoboxen',
        },
        {
          name: 'Hüpfburgen',
          link: '/produkt/huepfburgen',
        },
        {
          name: 'Event-Ausstattung',
          link: '/produkt/event-ausstattung',
        },
        {
          name: 'Spiel & Spaß',
          link: '/produkt/fun-food',
        },
        {
          name: 'Fun Food',
          link: '/produkt/fun-food',
        },
        {
          name: 'Zubehör',
          link: '/produkt/zubehoer',
        },
        {
          name: 'Kontakt',
          link: '/kontakt',
        },
      ],
      ADMIN: [
        {
          name: 'Produkte',
          link: '/admin/product',
        },
        {
          name: 'Bundles',
          link: '/admin/bundle',
        },
        {
          name: 'Kategorien',
          link: '/admin/category',
        },
      ],
      socialLinks: [{ icon: 'instagram', link: 'https://www.instagram.com/event.paradies/' }],
      isLoggedIn: {
        name: 'Abmelden',
      },
    },
  },

  home: {
    hero: {
      text: 'Event Paradies',
      subline: 'Alles für Ihr Event in Berlin und Brandenburg',
    },
    intro: {
      subline: 'Wer sind wir?',
      headline: 'Robin, Marco und Niclas',
      text: 'Wir sind Event-Paradies, Ihr Partner für unvergessliche Events in Berlin und Brandenburg. Wir bieten eine große Auswahl an Fotoboxen, Hüpfburgen, Fun Food und Event-Ausstattung. Unser Ziel ist es, Ihre Veranstaltung zu einem vollen Erfolg zu machen. Wir freuen uns auf Ihre Anfrage!',
      ctaText: 'Jetzt anfragen',
    },
    bundleInfo: {
      subline: 'Für jeden Anlass',
      headline: 'Unsere Bundles',
      text: 'Wählen Sie aus unseren sorgfältig zusammengestellten Event-Bundles, die alles beinhalten, was Sie für Ihre Feier benötigen – von Fotoboxen über Hüpfburgen bis hin zu weiterem Partyzubehör. Einmal buchen, alles da: So wird Ihr Event zum vollen Erfolg!',
      ctaText: 'Bundles  anzeigen',
    },
    faq: {
      headline: 'Häufig gestellte Fragen',
      items: [
        {
          title: 'Was ist Event-Paradies?',
          content:
            'Event-Paradies ist ein Unternehmen, das sich auf die Vermietung von Event-Equipment spezialisiert hat. Wir bieten eine große Auswahl an Fotoboxen, Hüpfburgen, Fun Food und Event-Ausstattung an. Unser Ziel ist es, Ihre Veranstaltung zu einem unvergesslichen Erlebnis zu machen.',
        },
        {
          title: 'Was ist Event-Paradies?',
          content:
            'Event-Paradies ist ein Unternehmen, das sich auf die Vermietung von Event-Equipment spezialisiert hat. Wir bieten eine große Auswahl an Fotoboxen, Hüpfburgen, Fun Food und Event-Ausstattung an. Unser Ziel ist es, Ihre Veranstaltung zu einem unvergesslichen Erlebnis zu machen.',
        },
        {
          title: 'Was ist Event-Paradies?',
          content:
            'Event-Paradies ist ein Unternehmen, das sich auf die Vermietung von Event-Equipment spezialisiert hat. Wir bieten eine große Auswahl an Fotoboxen, Hüpfburgen, Fun Food und Event-Ausstattung an. Unser Ziel ist es, Ihre Veranstaltung zu einem unvergesslichen Erlebnis zu machen.',
        },
        {
          title: 'Was ist Event-Paradies?',
          content:
            'Event-Paradies ist ein Unternehmen, das sich auf die Vermietung von Event-Equipment spezialisiert hat. Wir bieten eine große Auswahl an Fotoboxen, Hüpfburgen, Fun Food und Event-Ausstattung an. Unser Ziel ist es, Ihre Veranstaltung zu einem unvergesslichen Erlebnis zu machen.',
        },
        {
          title: 'Was ist Event-Paradies?',
          content:
            'Event-Paradies ist ein Unternehmen, das sich auf die Vermietung von Event-Equipment spezialisiert hat. Wir bieten eine große Auswahl an Fotoboxen, Hüpfburgen, Fun Food und Event-Ausstattung an. Unser Ziel ist es, Ihre Veranstaltung zu einem unvergesslichen Erlebnis zu machen.',
        },
        {
          title: 'Was ist Event-Paradies?',
          content:
            'Event-Paradies ist ein Unternehmen, das sich auf die Vermietung von Event-Equipment spezialisiert hat. Wir bieten eine große Auswahl an Fotoboxen, Hüpfburgen, Fun Food und Event-Ausstattung an. Unser Ziel ist es, Ihre Veranstaltung zu einem unvergesslichen Erlebnis zu machen.',
        },
      ],
    },
  },

  contact: {
    subline: 'Hier sind unsere',
    headline: 'Kontaktdaten',
    locations: [
      {
        contact: 'Robin Raue',
        phone: '0155 61 10 20 85',
        mail: 'robin.raue@event-paradies.de',
        city: '12309 Berlin',
        qrLink: 'https://wa.me/message/3S7ZQVR5DBTBG1',
      },
      {
        contact: 'Marco Gattulli',
        phone: '0155 61 10 20 74',
        mail: 'marco.gattulli@event-paradies.de',
        city: '15827 Blankenfelde-Mahlow',
        qrLink: 'https://wa.me/message/XJ4A3G3PHXUJP1',
      },
      {
        contact: 'Niclas Siekierka',
        phone: '0155 66 64 47 32',
        mail: 'niclas.siekierka@event-paradies.de',
        city: '15806 Zossen',
        qrLink: 'https://wa.me/message/S6I6XLPGYB4YD1',
      },
    ],
  },

  product: {
    back: 'Zurück',
    priceFrom: 'Ab ',
    depositPrefix: 'Kaution: ',
    show: 'Ansehen',
    detail: {
      technicalSheetDownload: 'Datenblatt herunterladen',
      operatingInstructionsDownload: 'Bedienungsanleitung herunterladen',
    },
    list: {
      titleSplit: {
        suffix: 'Produkte.',
      },
      search: 'Suche',
      employeeCount: 'Produkte',
    },
    upsert: {
      title: {
        create: 'Produkt hinzufügen',
        update: 'Produkt bearbeiten',
      },
      logoUploadLabel: 'Bilder hochladen',
      technicalSheetUploadLabel: 'Datenblatt hochladen',
      operatingInstructionsUploadLabel: 'Bedienungsanleitungen hochladen',
      viewDocument: 'Dokument ansehen',
      success: 'Das Produkt wurde erfolgreich gespeichert',
      error: 'Das Produkt konnte nicht gespeichert werden',
    },
    attributes: {
      name: 'Name',
      description: 'Beschreibung',
      price: 'Preis',
      subline: 'Untertitel',
    },
    propertyManager: {
      title: 'Eigenschaften',
    },
    imageManager: {
      title: 'Bilder',
    },
    documentManager: {
      title: 'Dokumente',
    },
    priceManager: {
      title: 'Preise',
      startingPrice: 'Einzelpreis',
      deposit: 'Kaution',
      hint: 'Hinweis',
      value: 'Wert',
      type: {
        title: 'Preisart',
        SINGLE: 'Einzel',
        PACKAGES: 'Pakete',
      },
      additional: {
        title: 'Zusatzkosten',
        text: 'Name',
        price: 'Preis',
      },
      packages: {
        title: 'Pakete',
        name: 'Name',
        text: 'Beschreibung',
        price: 'Preis',
      },
    },
    deliveryHint: {
      title: 'Abholung/Lieferung',
      text: 'Kostenfreie Abholung an einem unserer Standorte oder Lieferung gegen Aufpreis, abhängig von der Entfernung.',
    },
    suggestions: {
      title: 'Diese Artikel werden oft zusammen gebucht',
    },
    orderOverview: {
      preTitle: '3 Schritte zu ihrem',
      title: 'Wunschprodukt',
      request: {
        title: 'Anfrage',
        text: 'Sie senden uns Ihre Anfrage mit Ihren Wunschprodukten und dem gewünschten Zeitraum. Wahlweise können Sie uns anrufen.',
      },
      book: {
        title: 'Buchung',
        text: 'Sie erhalten von uns eine Bestätigung des gewünschten Mietzeitraumes.',
      },
      delivery: {
        title: 'Lieferung/Abholung',
        text: 'Wir liefern Ihnen die Produkte pünktlich zum vereinbarten Zeitpunkt oder Sie holen Ihre Produkte in einem unserer Standorte ab.',
      },
    },
    cart: {
      addCTA: 'Zur Anfrage hinzufügen',
      selectPackageFirst: 'Wählen Sie Ihr Paket',
      removeCTA: 'Aus Anfrage entfernen',
    },
    sorting: {
      ASC: 'Preis aufsteigend',
      DESC: 'Preis absteigend',
    },
  },
  category: {
    list: {
      titleSplit: {
        suffix: 'Kategorien.',
      },
      search: 'Suche',
      employeeCount: 'Kategorien',
    },
    upsert: {
      title: {
        create: 'Kategorie hinzufügen',
        update: 'Kategorie bearbeiten',
      },
      logoUploadLabel: 'Titelbild hochladen',
      success: 'Der Kategorie wurde erfolgreich gespeichert',
      error: 'Der Kategorie konnte nicht gespeichert werden',
    },
    attributes: {
      name: 'Name',
    },
  },
  bundle: {
    title: 'Bundles',
    includedProducts: 'Enthaltene Produkte:',
    addAllHint:
      'Ihnen gefällt unser Bundle? Fügen Sie alle Produkte mit einem Klick zu Ihrer Anfrage hinzu. Sie können die Auswahl im Warenkorb noch anpassen.',
    addToCart: 'Zur Anfrage hinzufügen',
    list: {
      titleSplit: {
        suffix: 'Bundles.',
      },
      search: 'Suche',
      employeeCount: 'Bundles',
    },
    upsert: {
      title: {
        create: 'Bundle hinzufügen',
        update: 'Bundle bearbeiten',
      },
      coverUploadLabel: 'Cover hochladen',
      success: 'Der Bundle wurde erfolgreich gespeichert',
      error: 'Der Bundle konnte nicht gespeichert werden',
    },
    attributes: {
      name: 'Name',
      description: 'Beschreibung',
    },
    productManager: {
      title: 'Produkte',
      selection: {
        title: 'Produkt auswählen',
      },
    },
  },

  cart: {
    preview: {
      title: 'Ihre Anfrage',
      cta: 'Jetzt anfragen',
      minValue: 'Anfragewert',
      empty: 'Ihr Warenkorb ist aktuell noch leer.',
    },
    checkout: {
      title: 'Ihre Angaben',
      hint: 'Bitte geben Sie Ihre Kontaktdaten ein, damit wir Ihre Anfrage bearbeiten können. Wir melden uns schnellstmöglich mit einem Angebot bei Ihnen.',
      text: 'Haben Sie noch Fragen oder Wünsche?',
      totalHint:
        'Der Anfragewert beinhaltet ausschließlich die Mietkosten pro Tag. Die Kaution und Lieferkosten werden in unserem unverbindlichen Angebot ergänzt.',
      dateRange: 'Zeitraum wählen',
      submit: 'Anfrage absenden',
      error: 'Ihre Anfrage konnte nicht gesendet werden. Bitte versuchen Sie es später erneut.',
      success: 'Wir haben Ihre Anfrage erhalten und melden uns schnellstmöglich bei Ihnen.',
      preferredContact: {
        title: 'Bevorzugte Kontaktaufnahme',
        PHONE: 'Telefon',
        MAIL: 'E-Mail',
        WHATSAPP: 'WhatsApp',
        SMS: 'SMS',
      },
      deliveryMethod: {
        title: 'Möchten Sie Ihre Produkte liefern lassen oder abholen?',
        DELIVERY: 'Lieferung',
        PICKUP: 'Abholung',
      },
    },
  },

  admin: {
    product: {
      list: {
        hasImages: 'Bilder vorhanden',
      },
      initProductDataImport: {
        title: 'Produktdaten importieren',
        text: 'Hiermit werden die Produkt- und Kategoriedaten aus der Datenbank gelöscht und durch die Standarddaten ersetzt. Diese Aktion kann nicht rückgängig gemacht werden.',
        cta: 'Produktdaten importieren',
      },
    },
  },

  login: {
    headline: 'Anmelden',
    cta: 'Anmelden',
    error: 'Anmeldung fehlgeschlagen. Falsche E-Mail oder Passwort.',
  },

  footer: {
    legalLinks: [
      { name: 'Impressum', link: '/impressum' },
      { name: 'Datenschutz', link: '/datenschutz' },
      { name: "AGB's", link: '/agb' },
    ],
    copyright: `Copyright ${new Date().getFullYear()}, all rights reserved.`,
  },

  generic: {
    level: {
      title: 'Rolle',
      ADMIN: 'Administrator',
    },
    company: {
      name: 'Event Paradies Nimaro GmbH',
      street: 'Eibenweg 23',
      city: '15827 Blankenfelde-Mahlow',
      phone: '+49 172 730 386 2',
      mail: 'info@event-paradies.de',
    },
    settings: 'Einstellungen',
    hourSuffix: ' Stunden',
    squareMeterSuffix: ' m²',
    timestampPrefix: 'Erstellt am: ',
    phonePrefix: 'Telefon: ',
    mailPrefix: 'E-Mail: ',
    websitePrefix: 'Webseite: ',
    employeeSuffix: ' Mitarbeiter',
    salutation: 'Anrede',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtsdatum',
    personType: 'Art',
    personTypePlaceholder: 'Beirat, Geschäftsführer, ...',
    street: 'Straße, Hausnummer',
    companyName: 'Firmenname',
    club: 'Verein (optional)',
    city: 'Stadt',
    mail: 'E-Mail-Adresse',
    postcode: 'Postleitzahl',
    identifier: 'E-Mail-Adresse',
    message: 'Ihre Anfrage...',
    phone: 'Telefonnummer',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    createdOn: 'Erstellt am',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    send: 'Senden',
    error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    acceptAGB: 'Ich akzeptiere die AGB',
    percent: ' %',
    kilometerSuffix: ' km',
    upload: 'Hochladen',
    login: 'Anmelden',
    logout: 'Abmelden',
    export: 'Export',
    import: 'Import',
    save: 'Speichern',
  },

  UI: {
    categorySelector: {
      title: 'Kategorieauswahl',
    },
    removeConfirmation: {
      title: 'Bestätigung',
      text: (itemName: string) => (!!itemName ? `Möchtest du "${itemName}" wirklich löschen?` : ''),
    },
    copyConfirmation: {
      title: 'Kopieren',
      text: (itemName: string) => `${itemName}-Kopie umbenennen zu:`,
      initNameSuffix: '-Kopie',
    },
    simpleList: {
      search: 'Suche',
      resultAmountSuffixPlural: ' Ergebnisse',
      resultAmountSuffixSingular: ' Ergebnis',
      noData: 'Keine Daten vorhanden',
    },
    card: {
      small: {
        cta: 'Ansehen',
      },
    },
    dropdown: {
      emptySelection: 'Bitte wählen',
      search: 'Suche',
    },
    selectableTagList: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger',
    },
    textarea: {
      max: {
        prefix: 'maximal ',
        suffix: ' Zeichen',
      },
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwörter stimmen nicht überein',
    passwordReset: 'Passwort wurde erfolgreich geändert',
    save: 'Ändern',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    title: 'Passwort ändern',
  },
}

export const langKey = 'DE'
