import Contact from '../Contact/Contact'
import Layout from '../UI/Layout/Layout'
import Intro from './Intro/Intro'
import BundleInfo from './BundleInfo/BundleInfo'
import FAQ from './FAQ'
import Hero from '../Hero/Hero'
import image from '../../assets/image/home/image.jpg'
import { useSelector } from 'react-redux'
import props from '../../redux/props'

const Home = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Layout authLevelRequired={false}>
      <Hero showLogo subline={t.home.hero.subline} image={image} />
      <Intro />
      <BundleInfo />
      <Contact />
      {/* <FAQ /> */}
    </Layout>
  )
}

export default Home
