import DirectFileUpload, { OnCallbackFile, UPLOAD_TYPES } from '../../../../UI/DirectFileUpload/DirectFileUpload'
import { fileMaxFileSizeInBytes, fileMimeTypes, s3PublicStoragePrefix } from '../../../../../utility'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'

type Props = {
  label: string
  value: string
  onChange: (key: string) => void
}

const FileUpload: React.FC<Props> = ({ label, value, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const onFile = (file: OnCallbackFile) => {
    if (file && file.key) {
      onChange(file.key)
    }
  }

  return (
    <div className="flex flex-col gap-5 border border-gold rounded-lg p-3 shadow-sm w-full">
      <DirectFileUpload
        uploadType={UPLOAD_TYPES.PRODUCT_DOCUMENT}
        allowedMimeTypes={fileMimeTypes}
        maxFileSizeInBytes={fileMaxFileSizeInBytes}
        onFilesCallback={onFile}
        label={label}
      />
      {value && (
        <div>
          <a href={`${s3PublicStoragePrefix}${value}`} target="_blank" rel="noreferrer" className="text-black hover:underline">
            {t.product.upsert.viewDocument}
          </a>
        </div>
      )}
    </div>
  )
}

export default FileUpload
