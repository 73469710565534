interface IButton {
  text?: string | React.ReactNode
  type?: string
  href?: string
  onClick?: () => void
  className?: string
  disabled?: boolean
  small?: boolean
  invert?: boolean
  contrast?: boolean
  newTab?: boolean
}

export enum Type {
  DEFAULT = 'button',
  SUBMIT = 'submit',
}

const Button: React.FC<IButton> = ({
  text,
  onClick,
  href,
  small = false,
  type = Type.DEFAULT,
  className = '',
  disabled = false,
  invert = false,
  contrast = false,
  newTab = false,
}) => {
  const getClasses = () => {
    let classes = `rounded-lg border font-light w-fit block transition-all ${
      small ? 'py-[2px] px-2 sm:px-3 md:px-5 text-sm' : 'py-1 px-3 sm:px-5 md:px-10'
    } ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`
    if (invert) {
      classes += ` border-white bg-white text-black ${!disabled && 'hover:bg-black hover:text-white'}`
    } else if (contrast) {
      classes += ` border-gold bg-gold text-white font-bold ${!disabled && 'hover:bg-transparent hover:text-gold hover:bg-white'}`
    } else {
      classes += ` border-black bg-black text-white ${!disabled && 'hover:bg-white hover:text-black'}`
    }
    return classes
  }

  if (!!href) {
    return (
      <a className={`text-center ${getClasses()} ${className}`} href={href} target={newTab ? '_blank' : '_self'} rel="noreferrer">
        {text}
      </a>
    )
  }
  return (
    <button
      className={`${getClasses()} ${className}`}
      type={type === Type.SUBMIT ? Type.SUBMIT : Type.DEFAULT}
      onClick={type !== Type.SUBMIT ? onClick : null}
      disabled={disabled}
    >
      {text}
    </button>
  )
}

export default Button
