import styled from 'styled-components'
import { Bundle as BundleType } from '../../types/Bundle'
import { s3PublicStoragePrefix } from '../../utility'

type Props = {
  data: BundleType
}

const Wrapper = styled.a<{ img: string }>`
  background-image: url(${(props) => `${s3PublicStoragePrefix}${props.img}`});
  width: calc(50% - 10px);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

const Bundle: React.FC<Props> = ({ data }) => {
  return (
    <Wrapper href={`/bundles/${data.uuid}`} className="aspect-video bg-cover group flex items-center justify-center rounded-lg" img={data.imageKey}>
      <div className="font-bold text-5xl text-white drop-shadow-md group-hover:scale-110 transition-all">{data.name}</div>
    </Wrapper>
  )
}

export default Bundle
