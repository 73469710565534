import { useState } from 'react'
import Input from '../../../UI/Input/Input'
import { PricingType, Product } from '../../../../types/Product'
import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type as ToastType } from '../../../hooks/useToast'
import Button, { Type } from '../../../UI/Button/Button'
import SlideIn from '../../../UI/SlideIn/SlideIn'
import { PlusIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
import Tooltip from '../../../UI/Tooltip/Tooltip'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import CategorySelector from '../../../UI/CategorySelector/CategorySelector'

type Props = {
  initData?: Product
  pullList?: () => void
}

const Upsert: React.FC<Props> = ({ initData, pullList }) => {
  const [data, setData] = useState<Product>(
    initData || {
      price: {
        pricingType: PricingType.SINGLE,
        startingPrice: 0,
        deposit: 0,
        additional: [],
        packages: [],
        hint: '',
      },
    },
  )
  const t = useSelector((s) => s[props.TRANSLATION])
  const productFeature = crud.use(props.PRODUCT)
  const toast = useToast()
  const navigate = useNavigate()

  const set = (key: keyof Product) => (value: any) => setData({ ...data, [key]: value })

  const save = async (): Promise<boolean> => {
    const product = await productFeature.upsert(data)
    if (product) {
      if (!!initData) {
        pullList()
        toast(t.product.upsert.success, ToastType.SUCCESS)
      } else {
        navigate(`/admin/product/${product.uuid}`)
      }
      return true
    } else {
      toast(t.product.upsert.error)
      return false
    }
  }

  const remove = async () => {
    await productFeature.remove({ uuid: data.uuid })
    pullList()
  }

  return (
    <SlideIn
      title={t.product.upsert.title[data.uuid ? 'update' : 'create']}
      formCallback={save}
      removeCallback={initData && remove}
      removeItemName={initData && data.name}
      Trigger={
        initData ? (
          <div>
            <Tooltip text={t.generic.settings}>
              <Cog6ToothIcon className="w-6 stroke-black" />
            </Tooltip>
          </div>
        ) : (
          <div className="">
            <PlusIcon className="w-5 cursor-pointer" />
          </div>
        )
      }
    >
      <div className="flex flex-col gap-3">
        <Input label={t.product.attributes.name} value={data.name} onChange={set('name')} required />
        <CategorySelector value={data.categoryUUID} onChange={set('categoryUUID')} />
        <Button contrast className="ml-auto" type={Type.SUBMIT} text={t.generic.save} />
      </div>
    </SlideIn>
  )
}

export default Upsert
