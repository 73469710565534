import SlideIn from '../UI/SlideIn/SlideIn'
import useLoadProducts from '../hooks/useLoadProducts'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import ProductList from './ProductList'
import useCart from './useCart'
import Button from '../UI/Button/Button'
import { formatEUR } from '../../utility'
import { getTotal } from './helper'

const Preview = ({ children }) => {
  const { cart } = useCart()
  const [products] = useLoadProducts()
  const t = useSelector((s) => s[props.TRANSLATION])

  if (!products || !cart) return null
  return (
    <SlideIn title={t.cart.preview.title} Trigger={children}>
      <div className="flex-1 h-fit">
        <div>
          <div className="pt-10 flex justify-between font-medium text-gray-900">
            <p className="font-bold text-md">{t.cart.preview.minValue}</p>
            <p className="font-bold text-md">{formatEUR(getTotal(cart, products))}</p>
          </div>
          <p className="mt-3 text-sm">{t.cart.checkout.totalHint}</p>
          {cart.productList.length > 0 && (
            <div className="pb-10 mt-4">
              <Button className="w-full text-lg" contrast text={t.cart.preview.cta} href={'/checkout'} />
            </div>
          )}
          {cart.productList.length === 0 && <div className="pb-10 mt-5 text-center font-bold">{t.cart.preview.empty}</div>}
          <ProductList isCartMode productList={cart.productList} />
        </div>
      </div>
    </SlideIn>
  )
}

export default Preview
