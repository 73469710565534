import { useSelector } from 'react-redux'
import { imageMaxFileSizeInBytes, imageMimeTypes } from '../../../../../utility'
import DirectFileUpload, { OnCallbackFile, UPLOAD_TYPES } from '../../../../UI/DirectFileUpload/DirectFileUpload'
import DNDList from '../../../../UI/DNDList/DNDList'
import props from '../../../../../redux/props'
import { Product } from '../../../../../types/Product'
import Image from '../../../../UI/Image/Image'
import RemoveConfirmation from '../../../../UI/RemoveConfirmation/RemoveConfirmation'
import { TrashIcon } from '@heroicons/react/24/solid'

type Props = {
  product: Product
  setImageKeyList: (imageKeyList: string[]) => void
}

const ImageManager: React.FC<Props> = ({ product, setImageKeyList }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const onImage = (files: OnCallbackFile[]) => {
    const imageKeyList = files.filter((file) => !!file?.key).map((file) => file.key)
    const currentImageKeyList = Array.isArray(product.imageKeyList) ? product.imageKeyList : []
    setImageKeyList([...currentImageKeyList, ...imageKeyList])
  }

  const remove = (key: string) => async () => {
    const currentImageKeyList = Array.isArray(product.imageKeyList) ? product.imageKeyList : []
    setImageKeyList(currentImageKeyList.filter((imageKey) => imageKey !== key))
  }

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="font-bold text-xl mt-5">{t.product.imageManager.title}</div>
      <div className="flex flex-col gap-5 border border-gold rounded-lg p-3 shadow-sm w-full">
        <DirectFileUpload
          uploadType={UPLOAD_TYPES.PRODUCT_IMAGE}
          allowedMimeTypes={imageMimeTypes}
          maxFileSizeInBytes={imageMaxFileSizeInBytes}
          onFilesCallback={onImage}
          label={t.product.upsert.logoUploadLabel}
          multiple
        />

        <DNDList
          orderUpdateCallback={setImageKeyList}
          items={product.imageKeyList.map((imageKey, index: number) => {
            return {
              id: imageKey,
              data: imageKey,
              content: (
                <div key={imageKey} className="flex flex-row items-start border-b border-cyan border-opacity-20 py-5 gap-3 bg-white">
                  <div className="flex flex-row gap-3">
                    <div className="w-8 aspect-square bg-black flex items-center justify-center text-white">{index + 1}</div>
                    <Image key={imageKey} src={imageKey} className="w-20" />
                  </div>
                  <RemoveConfirmation confirmationCallback={remove(imageKey)}>
                    <TrashIcon className="fill-blue opacity-40 hover:opacity-100 transition-all w-6 cursor-pointer" />
                  </RemoveConfirmation>
                </div>
              ),
            }
          })}
        />
      </div>
    </div>
  )
}

export default ImageManager
