import { ShoppingCartIcon } from '@heroicons/react/24/solid'
import useCart from './useCart'
import Preview from './Preview'

const Cart: React.FC<{}> = () => {
  const { cart } = useCart()

  if (!cart) return null
  return (
    <Preview>
      <div className="relative cursor-pointer">
        <ShoppingCartIcon className={`w-8 lg:w-6 fill-black`} />
        <div className="absolute -top-1 left-[60%] bg-gold text-white p-1 min-w-4 h-4 rounded-full text-sm flex items-center justify-center">
          {cart.productList.length}
        </div>
      </div>
    </Preview>
  )
}

export default Cart
