import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'

type Props = {
  message: string
  type: Type
}

export enum Type {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

const Toast: React.FC<Props> = ({ message, type }) => {
  const [isVisible, setIsVisible] = useState(true)

  const getIcon = () => {
    switch (type) {
      case Type.ERROR:
        return <XCircleIcon className="w-5 h-5 fill-red" />
      case Type.SUCCESS:
        return <CheckCircleIcon className="w-5 h-5 fill-green" />
      default:
        return <ExclamationCircleIcon className="w-5 h-5 fill-orange" />
    }
  }

  if (!isVisible) return null
  return (
    <div className="fixed bottom-0 right-3 z-50">
      <div className="flex items-start w-full max-w-xs p-4 mb-4 text-gray-500 bg-white shadow" role="alert">
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100">{getIcon()}</div>
        <div className="ms-3 text-sm font-normal">{message}</div>
        <div className="ms-auto cursor-pointer">
          <XMarkIcon className="w-5 h-5" onClick={() => setIsVisible(false)} />
        </div>
      </div>
    </div>
  )
}

const useToast = () => {
  const trigger = (message: string, type: Type = Type.ERROR) => {
    const toast = document.createElement('div')
    document.body.appendChild(toast)
    ReactDOM.render(<Toast message={message} type={type} />, toast)

    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(toast)
      document.body.removeChild(toast)
    }, 5000)
  }

  return trigger
}

export default useToast
