interface IProgress {
  progress: number
  className?: string
}

const Progress: React.FC<IProgress> = ({ progress, className }) => {
  if (!progress || progress === 0 || progress === 100) return null
  return (
    <div className={`w-full bg-lightGray dark:bg-lightGray overflow-hidden ${className}`}>
      <div className="bg-gold text-xs font-medium text-white p-0.5 text-center leading-none" style={{ width: `${progress}%` }}>
        {`${progress}%`}
      </div>
    </div>
  )
}

export default Progress
