import { useState } from 'react'
import Progress from '../Progress/Progress'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { s3Upload } from '@think-internet/zeus-frontend-package/fetch/fetch'
import DragAndDrop from '../DragAndDrop/DragAndDrop'

type PresignedUploadResponse = {
  presignedURL: string
  key: string
  url?: string
}

export type OnCallbackFile = {
  url: string
  key: string
  name: string
  mimeType: string
}

type Props = {
  label?: string
  allowedMimeTypes: string[]
  maxFileSizeInBytes: number
  uploadType: UPLOAD_TYPES
  onFilesCallback: (files: OnCallbackFile[] | OnCallbackFile) => void
  children?: React.ReactNode
  multiple?: boolean
  className?: string
}

export enum UPLOAD_TYPES {
  PRODUCT_IMAGE = 'PRODUCT_IMAGE',
  PRODUCT_DOCUMENT = 'PRODUCT_DOCUMENT',
  CATEGORY_IMAGE = 'CATEGORY_IMAGE',
  BUNDLE_IMAGE = 'BUNDLE_IMAGE',
}

const DirectFileUpload: React.FC<Props> = ({ label, allowedMimeTypes, maxFileSizeInBytes, onFilesCallback, uploadType, multiple = false }) => {
  const [progress, setProgress] = useState<number>(0)
  const getImageUploadPresignedURL = functional.use(Routes.GET_FILE_UPLOAD_PRESIGNED_URL)

  const updateProgress = (numberOfFilesTotal, currentFileNumber) => (progress: number) => {
    const availablePercentagePerFile = 100 / numberOfFilesTotal
    const basePercentage = availablePercentagePerFile * currentFileNumber
    const progressPercentage = (availablePercentagePerFile / 100) * progress
    setProgress(basePercentage + progressPercentage)
  }

  const process = async (files: File[]) => {
    const outputFiles: OnCallbackFile[] = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const response: PresignedUploadResponse = await getImageUploadPresignedURL({
        type: uploadType,
        meta: { type: file.type, name: file.name, size: file.size },
      })
      if (!response) continue
      const { presignedURL, url, key } = response

      const uploadResult = await s3Upload(presignedURL, file, updateProgress(files.length, i))
      if (!uploadResult) continue
      outputFiles.push({ url, key, name: file.name, mimeType: file.type })
    }
    setTimeout(() => {
      setProgress(100)
      if (outputFiles.length > 0) {
        if (multiple) {
          onFilesCallback(outputFiles)
        } else {
          onFilesCallback(outputFiles[0])
        }
      }
    }, 100)
  }

  return (
    <>
      {(progress === 0 || progress === 100) && (
        <DragAndDrop multiple={multiple} onFiles={process} allowedMimeTypes={allowedMimeTypes} maxFileSizeInBytes={maxFileSizeInBytes}>
          <div className="flex items-center justify-center py-10 bg-white border rounded-md border-gold">
            <div className="text-sm text-gray">{label}</div>
          </div>
        </DragAndDrop>
      )}
      <Progress progress={progress} />
    </>
  )
}

export default DirectFileUpload
