import { useSelector } from 'react-redux'
import LargeContainer from '../../UI/Container/LargeContainer'
import Tripple from '../../UI/ProductFeature/Tripple/Tripple'
import props from '../../../redux/props'
import people from '../../../assets/image/home/intro/people.jpg'
import PalmBackground from '../../UI/PalmBackground/PalmBackground'

const Intro = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="py-16 md:py-32 relative">
      <LargeContainer>
        <div className="flex flex-col gap-16 md:gap-32 relative z-20">
          <div className="flex flex-col md:flex-row items-center gap-10">
            <div className="md:w-1/2">
              <img src={people} alt={''} className="w-full rounded-lg" />
            </div>
            <div className="md:w-1/2">
              <div className="text-gold text-lg md:text-xl leading-6">{t.home.intro.subline}</div>
              <div className=" font-bold text-2xl md:text-4xl">{t.home.intro.headline}</div>
              <div className="md:text-lg">{t.home.intro.text}</div>
            </div>
          </div>
          <div className="py-3 md:py-10">
            <Tripple />
          </div>
        </div>
      </LargeContainer>
      <PalmBackground />
    </div>
  )
}

export default Intro
