import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import { Level } from '../../types/Auth'
import Product from './Product/Product'
import Category from './Category/Category'
import Bundle from './Bundle/Bundle'

const Admin = () => {
  return (
    <Layout authLevelRequired={[Level.ADMIN]} noFooter smallHeader>
      <Routes>
        <Route path={'product/*'} element={<Product />} />
        <Route path={'category/*'} element={<Category />} />
        <Route path={'bundle/*'} element={<Bundle />} />
        <Route path={'*'} element={<Navigate replace to="/admin/product" />} />
      </Routes>
    </Layout>
  )
}

export default Admin
