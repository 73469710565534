import Container from '../UI/Container/Container'
import Layout from '../UI/Layout/Layout'
import Hero from './Hero/Hero'
import './style.scss'

const Agb = () => {
  return (
    <Layout authLevelRequired={false}>
      <Hero />
      <Container className="legal-document pb-10">
        <h1>Allgemeine Geschäftsbedingungen</h1>

        <h2>Vertragsabschluss</h2>
        <p>
          Für sämtliche Mietgeschäfte gelten ausschließlich die nachfolgenden Mietbedingungen. Geschäftsbedingungen des Mieters binden dem Vermieter
          nicht, auch wenn er ihnen nicht ausdrücklich widerspricht. Ein Mietvertrag kommt nur durch schriftliche Auftragsbestätigung des Vermieters
          zu Stande. Fehlt eine Auftragsbestätigung, gilt als Vertragsbestätigung die Abholung und/oder Auslieferung der Mietgegenstände. Angaben im
          Mietvertrag zum Mietzeitraum an Mietgegenständen sind verbindlich. Der Mieter kann beim Vermieter mit dessen Zustimmung kostenpflichtige
          Nebenleistungen bestellen. Hierzu gehören Elektronikzubehör, Aufsichtspersonen für Hüpfburgen, Reinigung der Mietgegenstände, Reparatur von
          Beschädigungen aus unsachgemäßer Handhabung. Nebenleistungen sind in den Vertragsunterlagen und Rechnungen gesondert als Position
          auszuweisen und vom Mieter zusätzlich zu vergüten.
        </p>

        <h2>Aus- und Rücklieferung</h2>
        <p>
          AUS- UND RÜCKLIEFERUNG Übernimmt der Vermieter den Transport der Mietgegenstände, trägt er die Transportgefahr bis zur Übergabe an den
          Mieter. Versandkosten, Frachtkosten, Verpackungskosten und Entladungskosten trägt der Mieter. Der Mieter hat dafür zu sorgen, dass gemietete
          und anderweitig beschaffte Gegenstände gleicher Art nicht vermischt werden. Im anderen Fall trägt der Mieter die Beweislast, welche der
          vermischten Mietgegenstände und welche anderweitig beschaPt sind. Die Rücklieferung der Mietgegenstände erfolgt auf Kosten und Gefahr des
          Mieters. Der Mieter ist verpflichtet zur vollständigen Rückgabe der Mietgegenstände im ursprünglichen Zustand ohne über den normalen
          Verschleiß hinausgehende Schäden, in gereinigten und wieder einsatzfähigen Zustand. Alle Mietgegenstände sind an den vereinbarten
          Rückgabeort zurückzugeben. Nicht zurückgegebene Mietgegenstände hat der Mieter nach den gesetzlichen Vorschriften zu ersetzen. Im
          Zweifelsfall hat der Mieter die Vollständigkeit der zurückgegebenen Mietgegenstände nachzuweisen. Der Mietpreis berücksichtigt den
          Verschleiß durch sachgerechte Nutzung. Der Mieter ist verpflichtet, die Mietgegenstände pfleglich und sorgsam zu behandeln. Auf eine
          Pflichtverletzung des Mieters zurückzuführende Schäden hat der Mieter durch Übernahme der Reparaturkosten zu ersetzen. Die gesetzlichen
          Regelungen über die Beweislast bleiben unberührt. Wegen der erforderlichen Sach- und Fachkompetenz sind Reparaturen nur vom Vermieter
          durchzuführen. Der Reinigungszustand der Mietgegenstände bei Rückgabe muss den bei Auslieferung geltenden Qualitätskriterien des Vermieters
          entsprechen. Im anderen Fall hat der Mieter dem Vermieter die Kosten der Reinigung zu erstatten. Der Vermieter stellt dem Mieter die
          Qualitätskriterien auf Anfrage kostenlos zur Verfügung.
        </p>

        <h2>Sorgfalt</h2>
        <p>
          Aufbau- und Verwendungsanleitungen für die Mietgegenstände, in der jeweils gültigen Fassung, stellt der Vermieter dem Mieter auf Anfrage
          jederzeit kostenlos zur Verfügung. Der Mieter ist verpflichtet, die Regelungen in den Aufbau- und Verwendungsanleitungen zu befolgen. Der
          Mieter hat die Mietgegenstände am Verwendungsort laufend zu überwachen. Der Mieter hat die Mietgegenstände sorgfältig gegen Diebstahl zu
          schützen. Im Falle eines Diebstahls ist der Mieter verpflichtet, diesen unverzüglich schriftlich beim Vermieter und der zuständigen
          Ordnungsbehörde anzuzeigen. Dem Vermieter ist eine Kopie der polizeilichen Anzeige zu übersenden. Treten Schäden irgendwelcher Art an den
          Mietgegenständen oder im Zusammenhang mit den Mietgegenständen auf, hat der Mieter den Beweis zu führen, dass diese Schäden entstanden sind,
          obwohl niedergelegten Verpflichtungen beachtet wurden. Die Mietgegenstände dürfen nicht an Dritte weitergegeben werden. Die Nutzung der
          Mietgegenstände auf einer anderen als der im Mietvertrag genannten Person bedarf der schriftlichen Zustimmung durch den Vermieter.
        </p>

        <h2>Vertragsdauer</h2>
        <p>
          Die Mindestmietdauer beträgt einen Tag, soweit nicht vertraglich etwas anderes vereinbart ist. Die Mietzeit beginnt mit dem Tag, an dem die
          Mietgegenstände das Lager des Vermieters verlassen und endet mit dem Tag der Rückgabe an den vertraglich vereinbarten Ort des Vermieters.
          Abhol- und Rückgabetag zählen jeweils als ein voller Miettag, soweit nicht vertraglich etwas anderes vereinbart ist. Werden Mietgegenstände
          zur Abholung an einem bestimmten Tag bestellt und trotz Bereitstellung nicht abgeholt, so wird die Miete spätestens vom dritten auf die
          vertragsmäßige Bereitstellung folgenden Tag an geschuldet, unabhängig vom Zeitpunkt der Abholung.
        </p>
        <h2>Miete und Zahlung</h2>
        <p>
          Der vereinbarte Mietbetrag wird bei Übergabe der Mietgegenstände vom Mieter an den Vermieter sofort fällig und ist in bar zu entrichten,
          sofern nichts anderes vereinbart ist. Verzugszinsen berechnen sind nach den gesetzlichen Vorschriften für Entgeltforderungen. Ein
          Zurückbehaltungs- oder Leistungsverweigerungsrecht steht dem Mieter zu, wenn es auf demselben Vertragsverhältnis beruht und er kein
          Unternehmer ist oder aber, wenn es auf demselben Vertragsverhältnis beruht und der betrePende Anspruch des Mieters unstreitig oder
          rechtskräftig festgestellt ist. Die Aufrechnung mit einer unstreitigen oder vom Vermieter anerkannten oder rechtskräftig gegen ihn
          festgestellten Forderung ist zulässig, in allen anderen Fällen jedoch ausgeschlossen. Der Vermieter ist zur fristlosen Kündigung des
          Vertrages aus wichtigem Grund insbesondere dann berechtigt, der der Mieter die Durchführung eines Insolvenzverfahrens über seine Vermögen
          beantragt oder wenn ein Insolvenzverfahren über das Vermögen des Mieters eröPnet oder die EröPnung mangels Masse abgewiesen wird. In diesen
          Fällen kann der Vermieter die Rückgabe der Mietgegenstände fordern und ist berechtigt, die Mietgegenstände vom Einsatzort des Mieters
          abzuholen. Die dadurch entstehenden Kosten trägt der Mieter. Nach Vertragskündigung ist dem Mieter eine Weiternutzung der Mietgegenstände
          nicht gestattet. Etwaige Rechte des Insolvenzverwalters bleiben unberührt.
        </p>
        <h2>Haftung</h2>
        <p>
          Die Mietgegenstände haben bei Auslieferung den zu diesem Zeitpunkt geltenden Qualitätskriterien des Vermieters zu entsprechen. Der Vermieter
          stellt dem Mieter die Qualitätskriterien auf Anfrage kostenlos zur Verfügung. Es obliegt allein dem Mieter, die für seine Zwecke geeigneten
          Mietgegenstände auszuwählen. Das Einsatzrisiko der Mietgegenstände trägt der Mieter. Die gesetzliche Haftung des Vermieters bleibt
          unberührt. Der Mieter ist verpflichtet, die Mietgegenstände entgegenzunehmen, sofern sie nicht wesentliche Mängel aufweisen. Der Mieter hat
          die Mietgegenstände nach Entgegennahme, sobald dies nach ordnungsgemäßem Geschäftsgang tunlich ist, auf Vollzähligkeit und
          Funktionstüchtigkeit zu prüfen. Erkennbare Mängel sind dem Vermieter unverzüglich schriftlich anzuzeigen, anderenfalls gelten die
          Mietgegenstände als genehmigt. Ein bei Entgegennahme nicht erkennbarer Mangel ist nach Entdeckung unverzüglich anzuzeigen. Im anderen Fall
          gelten die Mietgegenstände trotz dieses Mangels als genehmigt. Es genügt in jedem Fall die rechtzeitige Absendung der Anzeige. Hat der
          Vermieter einen Mangel arglistig verschwiegen, so kann er sich auf die vorstehenden Vorschriften nicht berufen. Der Mieter trägt nach
          Entgegennahme die Beweislast hinsichtlich Mängeln an den Mietgegenständen, insbesondere für den Mangel an sich, den Zeitpunkt der
          Feststellung des Mangels sowie die Rechtzeitigkeit der Mangel- anzeige. Bei begründeten Beanstandungen ist der Vermieter zur Ersatzlieferung
          berechtigt. Der Vermieter haftet im Rahmen der gesetzlichen Bestimmungen. Ein Einsatz der Mietgegenstände unter Verwendung von eigenen
          Teilen des Mieters oder Teilen anderer Hersteller erfolgt allein auf Gefahr des Mieters. Der Vermieter übernimmt keinerlei Haftung für vom
          Mieter oder von Dritten angefertigte bzw. aufgestellte Montageanweisungen des Mieters. Unberührt hiervon bleiben die Rechte des Mieters
          wegen Mängeln der Mietgegenstände sowie die Haftung des Vermieters wie sie im Mietvertrag und in diesen Geschäftsbedingungen geregelt sind.
          Der Mieter haftet für jede feuer-, wasser- und witterungsbedingte Beschädigung der Mietgegen- stände, sowie für Diebstahl durch Dritte, es
          sei denn, dass er den hieraus resultierenden Schaden nicht zu vertreten hat. Unbrauchbar gewordene oder verlorengegangene Mietgegenstände
          sind vom Mieter im Rahmen der gesetzlichen Vorschriften zu ersetzen. Der Mieter hat auch die Kosten für die Entsorgung unbrauchbarer
          Mietgegenstände zu tragen. Soweit beschädigt zurückgegebene Mietgegenstände nicht mehr repariert werden können (Totalschaden) oder wenn
          Mietgegenstände nicht zurückgegeben werden (Fehlgegenstände), hat der Mieter den Neuwert der Mietgegenstände zu ersetzen. Die bis zum
          Zeitpunkt entstandenen Ansprüche aus der Miete für den Vermieter bleiben unberührt. Der Mieter ist verpflichtet, dem Vermieter jederzeit
          Auskunft darüber zu geben, wo sich die Mietgegenstände befinden und ihm jederzeit Zugang zu ihnen zu verschaPen. Pfändungsversuche an den
          Mietgegenständen hat der Mieter dem Vermieter unverzüglich anzuzeigen und gleichzeitig alle zum Schutz des Eigentums des Vermieters
          erforderlichen Schritte zu unternehmen. Der Mieter erstattet dem Vermieter die Kosten für erforderliche Maßnahmen zur Wahrung seiner Rechte
          an den Mietgegenständen.
        </p>
        <h2>Nebenleistungen</h2>
        <p>Die Kosten für vereinbarte Zusatzleistungen beim Vermieter trägt der Mieter, ebenso für angemessene Anfahrtskosten des Vermieters.</p>

        <h2>Erfüllungsort, Sonstiges</h2>
        <p>Als Erfüllungsort für die Verpflichtung beider Vertragsteile wird 15827 Blankenfelde-Mahlow vereinbart Es gilt deutsches Recht.</p>

        <h2>Informationen zum Datenschutz nach EU-DSGVO</h2>
        <p>
          Seit dem 25. Mai 2018 gilt europaweit ein neues, einheitliches Datenschutzrecht. Die bereits 2016 in Kraft getretene EU-Datenschutz-
          Grundverordnung (EU-DSGVO) muss ab diesem Zeitpunkt in allen Mitgliedstaaten angewendet werden. Sie zielt darauf ab, den Schutz
          personenbezogener Daten zu stärken, und EU-weit zu vereinheitlichen. Die Daten werden so lange gespeichert, wie ihre Kenntnis für die
          Erfüllung des Zwecks der Speicherung notwendig ist. Notwendig ist die Kenntnis in der Regel für eine Speicherdauer von zunächst drei Jahren.
          Nach Ablauf wird geprüft, ob eine Speicherung weiterhin notwendig ist, andernfalls werden die Daten taggenau gelöscht. Im Falle der
          Erledigung eines Sachverhalts werden die Daten drei Jahre nach Erledigung taggenau gelöscht. Eintragungen im Schuldnerverzeichnis werden
          gemäß § 882e ZPO nach Ablauf von drei Jahren seit dem Tag der Eintragungsanordnung taggenau gelöscht.
        </p>
      </Container>
    </Layout>
  )
}

export default Agb
