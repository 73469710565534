import { PlusIcon } from '@heroicons/react/24/solid'
import { TrashIcon } from '@heroicons/react/24/outline'
import { Package } from '../../../../../types/Product'
import Input from '../../../../UI/Input/Input'
import Currency from '../../../../UI/Input/Currency'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { useEffect } from 'react'
import Textarea from '../../../../UI/Textarea/Textarea'
import { getUUID } from '../../../../../utility'

type Props = {
  value: Package[]
  onChange: (value: Package[]) => void
}

const Packages: React.FC<Props> = ({ value, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    if (!Array.isArray(value)) {
      onChange([{ uuid: getUUID(), name: '', text: '', price: 0 }])
    }
  }, [value, onChange])

  const updatePackage = (index: number, key: keyof Package) => (newValue: string) => {
    const updatedProperties = value.map((p, i) => (i === index ? { ...p, [key]: newValue } : p))
    onChange(updatedProperties)
  }

  const addPackage = () => {
    onChange([...value, { uuid: getUUID(), name: '', text: '', price: 0 }])
  }

  if (!Array.isArray(value)) return null
  return (
    <div className="w-full">
      <label className={'text-gray text-sm'}>{t.product.priceManager.packages.title}</label>
      <div className="flex flex-col gap-1">
        {value.map((property, index) => (
          <div className="flex gap-[20px] items-center bg-lightGray rounded-lg p-3 shadow-sm" key={`prop-${index}`}>
            <div className="flex flex-col w-full gap-5">
              <div className="flex gap-5">
                <Input placeholder={t.product.priceManager.packages.name} value={property.name} onChange={updatePackage(index, 'name')} />
                <Currency placeholder={t.product.priceManager.packages.price} value={property.price} onChange={updatePackage(index, 'price')} />
              </div>
              <Textarea placeholder={t.product.priceManager.packages.text} value={property.text} onChange={updatePackage(index, 'text')} />
            </div>
            <TrashIcon
              className="w-6 shrink-0 stroke-black hover:stroke-red cursor-pointer"
              onClick={() => onChange(value.filter((_, i) => i !== index))}
            />
          </div>
        ))}
      </div>
      <div className="w-fit p-1 bg-gold ml-auto cursor-pointer mt-1" onClick={addPackage}>
        <PlusIcon className="w-4 h-4" />
      </div>
    </div>
  )
}

export default Packages
