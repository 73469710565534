import { useParams } from 'react-router-dom'
import Products from '../Products/Products'
import Layout from '../UI/Layout/Layout'
import Hero from '../Hero/Hero'
import useLoadProducts from '../hooks/useLoadProducts'
import useLoadCategories from '../hooks/useLoadCategories'
import PalmBackground from '../UI/PalmBackground/PalmBackground'
import fallbackHeroImage from '../../assets/image/fallbackHero.jpg'
import LargeContainer from '../UI/Container/LargeContainer'
import { PricingType, Product } from '../../types/Product'
import { useState } from 'react'
import Dropdown from '../UI/Dropdown/Dropdown'
import props from '../../redux/props'
import { useSelector } from 'react-redux'

enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

const ProductCategory = () => {
  const { categoryUUID } = useParams()
  const [categories] = useLoadCategories()
  const [products] = useLoadProducts(categoryUUID)
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESC)
  const t = useSelector((s) => s[props.TRANSLATION])

  const sort = (products: Product[]) => {
    return products.sort((a, b) => {
      const priceA = a.price.pricingType === PricingType.PACKAGES ? a.price.packages[0].price : a.price.startingPrice
      const priceB = b.price.pricingType === PricingType.PACKAGES ? b.price.packages[0].price : b.price.startingPrice
      return sortDirection === SortDirection.ASC ? priceA - priceB : priceB - priceA
    })
  }

  if (!categories || !products) return <></>
  const category = categories.find((c) => c.uuid === categoryUUID)
  if (!category) return <></>
  return (
    <Layout authLevelRequired={false}>
      {!!category.imageKey && <Hero text={category.name} imageKey={category.imageKey} />}
      {!category.imageKey && <Hero text={category.name} image={fallbackHeroImage} />}
      <div className="pt-10 pb-32 relative">
        <LargeContainer>
          <div className="mt-0 relative z-30 flex justify-end w-fit ml-auto">
            <Dropdown
              disableSearch
              value={sortDirection}
              items={Object.values(SortDirection).map((d) => ({ label: t.product.sorting[d], value: d }))}
              onChange={setSortDirection}
            />
          </div>
          <div className="mb-10 mt-5 relative z-20">
            <Products items={sort(products.filter((p) => p.imageKeyList.length > 0))} />
          </div>
        </LargeContainer>
        <PalmBackground />
      </div>
    </Layout>
  )
}

export default ProductCategory
