import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import './Global.Styled'
import Home from './components/Home/Home'
import ProductCategory from './components/ProductCategory/ProductCategory'
import ProductDetail from './components/ProductDetail/ProductDetail'
import Login from './components/Login/Login'
import Admin from './components/Admin/Admin'
import Bundles from './components/Bundles/Bundles'
import Checkout from './components/Cart/Checkout/Checkout'
import BundleView from './components/Bundles/BundleView/BundleView'
import DataProtection from './components/Legal/DataProtection'
import Imprint from './components/Legal/Imprint'
import Agb from './components/Legal/Agb'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {/* home */}
            <Route index element={<Home />} />

            {/* bundles */}
            <Route path={'/bundles'} element={<Bundles />} />
            <Route path={'/bundles/:bundleUUID'} element={<BundleView />} />

            {/* product pages */}
            <Route path={'/kategorie/:categoryUUID'} element={<ProductCategory />} />
            <Route path={'/produkt/:categoryUUID/:productUUID'} element={<ProductDetail />} />

            {/* checkout */}
            <Route path={'/checkout'} element={<Checkout />} />

            {/* legal */}
            <Route path={'/impressum'} element={<Imprint />} />
            <Route path={'/datenschutz'} element={<DataProtection />} />
            <Route path={'/agb'} element={<Agb />} />

            {/* AUTH */}
            <Route path={'/login'} element={<Login />} />

            {/* Admin */}
            <Route path={'/admin/*'} element={<Admin />} />

            {/* fallback routing */}
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  )
}

export default App
