import logo from '../../../assets/image/logo/black.png'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'

const Hero = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div className="pt-20 md:pt-32 flex justify-center">
      <img src={logo} alt={t.projectName} className="w-[200px]" />
    </div>
  )
}

export default Hero
