import { useNavigate } from 'react-router-dom'
import Navigation from './Navigation/Navigation'
import logoWhite from '../../../../assets/image/logo/white_black.png'
import logoBlack from '../../../../assets/image/logo/white_black.png'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import useDidScroll from './useDidScroll'
import LargeContainer from '../../Container/LargeContainer'

type Props = { hideNavigation?: boolean; small?: boolean }

const Header: React.FC<Props> = ({ hideNavigation = false, small = false }) => {
  const navigate = useNavigate()
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const isLoggedIn = () => !!accountLink
  const t = useSelector((s) => s[props.TRANSLATION])
  const didScroll = useDidScroll()

  const goHome = () => {
    let path = '/'
    if (isLoggedIn()) {
      path = accountLink.level === 'ADMIN' ? '/admin' : '/employee'
    }
    navigate(path)
  }

  return (
    <div className={`z-30 ${didScroll || small ? 'py-2 h-16' : 'py-1 h-16 md:h-24'} transition-all top-0 left-0 fixed w-full flex items-center`}>
      <div className={`absolute top-0 left-0 w-full h-full -z-10 backdrop-blur-lg shadow-md bg-white bg-opacity-50`}></div>
      <LargeContainer className="relative">
        <div className="flex flex-row items-center justify-between">
          <div onClick={goHome} className={`cursor-pointer h-full`}>
            {small ? (
              <img src={logoBlack} alt={t.projectName} className={`transition-all ${didScroll || small ? 'h-12' : 'h-12 md:h-20'}`} />
            ) : (
              <>
                {!didScroll && (
                  <img src={logoWhite} alt={t.projectName} className={`transition-all ${didScroll || small ? 'h-12' : 'h-12 md:h-20'}`} />
                )}
                {!!didScroll && (
                  <img src={logoBlack} alt={t.projectName} className={`transition-all ${didScroll || small ? 'h-12' : 'h-12 md:h-20'}`} />
                )}
              </>
            )}
          </div>
          {!hideNavigation && <Navigation small={small} />}
        </div>
      </LargeContainer>
    </div>
  )
}

export default Header
