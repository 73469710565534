import { useSelector } from 'react-redux'
import Card from '../../Products/Card'
import useGetSuggestions from './useGetSuggestions'
import props from '../../../redux/props'
import Carousel from '../../UI/Carousel/Carousel'

type Props = {
  productUUID: string
}

const Suggestions: React.FC<Props> = ({ productUUID }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const suggestions = useGetSuggestions(productUUID)

  if (!suggestions) return null
  return (
    <div className="mt-20 md:mt-36">
      <div className="font-bold text-xl text-gold text-center">{t.product.suggestions.title}</div>
      <div className="mt-5">
        <Carousel
          itemsToShow={4}
          itemsToShowMobile={1}
          items={suggestions.map((suggestion) => {
            return (
              <div className="w-full">
                <Card product={suggestion} fullWidth />
              </div>
            )
          })}
        />
      </div>
    </div>
  )
}

export default Suggestions
