import { useSelector } from 'react-redux'
import { Price, PricingType } from '../../../../../types/Product'
import props from '../../../../../redux/props'
import Input from '../../../../UI/Input/Input'
import Currency from '../../../../UI/Input/Currency'
import RadioGroup from '../../../../UI/RadioGroup/RadioGroup'
import Additional from './Additional'
import Packages from './Packages'

type Props = {
  value: Price
  onChange: (value: Price) => void
}

const PriceManager: React.FC<Props> = ({ value, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const set = (key: keyof Price) => (newValue: any) => {
    onChange({ ...value, [key]: newValue })
  }

  if (!value) return null
  return (
    <div className="w-full">
      <div className="font-bold text-xl mt-5">{t.product.priceManager.title}</div>
      <div className="flex flex-row gap-[20px] flex-wrap border border-gold rounded-lg p-3 shadow-sm">
        <RadioGroup
          label={t.product.priceManager.type.title}
          options={Object.values(PricingType).map((o) => ({ label: t.product.priceManager.type[o], value: o }))}
          value={value.pricingType}
          onChange={set('pricingType')}
        />
        {value.pricingType === PricingType.SINGLE && (
          <Currency
            type="currency"
            required
            label={t.product.priceManager.startingPrice}
            value={value.startingPrice}
            onChange={set('startingPrice')}
          />
        )}
        {value.pricingType === PricingType.PACKAGES && <Packages value={value.packages} onChange={set('packages')} />}
        <Currency type="currency" label={t.product.priceManager.deposit} value={value.deposit} onChange={set('deposit')} />
        <Additional value={value.additional} onChange={set('additional')} />
        <Input label={t.product.priceManager.hint} value={value.hint} onChange={set('hint')} />
      </div>
    </div>
  )
}

export default PriceManager
