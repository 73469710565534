import Palme_01 from '../../../assets/image/palmBackground/Palme_01.png'
import Palme_02 from '../../../assets/image/palmBackground/Palme_02.png'

const PalmBackground = () => {
  return (
    <div className="absolute w-full h-full bottom-0 left-0 flex items-end overflow-hidden">
      <div className="flex justify-between px-10">
        <img src={Palme_01} alt={''} className="w-1/3 h-full object-contain" />
        <img src={Palme_02} alt={''} className="w-1/3 h-full object-contain" />
      </div>
    </div>
  )
}

export default PalmBackground
