import { NavigationItem } from '../../../types'
import useDidScroll from '../../../useDidScroll'

type Props = {
  data: NavigationItem
  className?: string
  onClick?: () => void
  mobile?: boolean
  small?: boolean
  isSelected?: boolean
}

const Default: React.FC<Props> = ({ data, className = '', onClick = null, small = false, isSelected = false }) => {
  const didScroll = useDidScroll()

  const clicked = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <div className={`flex flex-row gap-1 items-center`} onClick={clicked}>
      <a
        href={data.link || ''}
        className={`${!didScroll && !small && 'lg:text-black'} ${
          isSelected && 'font-bold'
        } lg:text-md no-underline hover:underline hover:bg-opacity-20 py-1 lg:px-3 rounded-full ${className}`}
      >
        {data.name}
      </a>
    </div>
  )
}

export default Default
