import props from '../../redux/props'
import { useDispatch, useSelector } from 'react-redux'
import { Cart, ProductCartItem } from './types'
import { PricingType, Product } from '../../types/Product'
import { setLocal } from '../../redux/action/local'
import { useEffect } from 'react'
import cookie, { CART_USER_UUID } from '../UI/helper/cookie'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import { getUUID } from '../../utility'
import { getProduct } from './helper'
import useLoadProducts from '../hooks/useLoadProducts'

type Add = (product: Product, packageUUID: string) => void
type AddBundle = (productUUIDList: string[]) => void
type Remove = (productUUID: string) => void
type IsIncluded = (product: Product) => boolean
type Clear = () => void
type Response = {
  cart: Cart
  isIncluded: IsIncluded
  add: Add
  remove: Remove
  clear: Clear
  addBundle: AddBundle
}

const useCart = (): Response | null => {
  const cart: Cart = useSelector((s) => s[props.CART])
  const dispatch = useDispatch()
  const getCart = functional.use(Routes.LOAD_CART)
  const saveCart = functional.use(Routes.SAVE_CART)
  const [products] = useLoadProducts()

  useEffect(() => {
    const pullCart = async (userUUID: string) => {
      const cart = await getCart({ userUUID })
      if (cart) {
        dispatch(setLocal(props.CART, cart))
      }
    }

    const userUUID = cookie.get(CART_USER_UUID)
    if (!cart && !!userUUID) {
      pullCart(userUUID)
    } else if (!userUUID && !cart) {
      const cartUserUUID = getUUID()
      cookie.set(CART_USER_UUID, cartUserUUID)
      pullCart(cartUserUUID)
    }
  }, [cart, getCart, dispatch, saveCart])

  const isIncluded: IsIncluded = (product: Product) => {
    return cart.productList.some((p) => p.uuid === product.uuid)
  }

  const save = async (productList: ProductCartItem[]) => {
    const uniqueProductList = productList.filter((p, i) => productList.findIndex((p2, j) => p2.uuid === p.uuid && i !== j) === -1)
    const newCart = await saveCart({
      userUUID: cart.userUUID,
      cart: {
        ...cart,
        productList: uniqueProductList,
      },
    })
    if (newCart) {
      dispatch(setLocal(props.CART, newCart))
    }
  }

  const add: Add = (product: Product, packageUUID: string) => {
    if (!isIncluded(product)) {
      const productList = [...cart.productList, { uuid: product.uuid, packageUUID }]
      save(productList)
    }
  }

  const remove: Remove = (productUUID: string) => {
    const products = cart.productList.filter((p) => p.uuid !== productUUID)
    save(products)
  }

  const addBundle: AddBundle = (productUUIDList: string[]) => {
    const resolvedProducts = []
    for (const uuid of productUUIDList) {
      const product = getProduct(products, uuid)
      if (!cart.productList.some((p) => p.uuid === uuid)) {
        if (product.price.pricingType === PricingType.PACKAGES) {
          resolvedProducts.push({ uuid, packageUUID: product.price.packages[0].uuid })
        } else {
          resolvedProducts.push({ uuid })
        }
      }
    }
    save([...resolvedProducts, ...cart.productList])
  }

  const clear = () => save([])

  if (!cart) return { cart: null, isIncluded: null, add: null, remove: null, clear: null, addBundle: null }
  return {
    cart,
    isIncluded,
    add,
    remove,
    clear,
    addBundle,
  }
}

export default useCart
