import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Dropdown from '../Dropdown/Dropdown'
import useLoadCategories from '../../hooks/useLoadCategories'

type Props = {
  value: string
  onChange: (value: string) => void
  contrast?: boolean
}

const CategorySelector: React.FC<Props> = ({ value, onChange, contrast }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [categories] = useLoadCategories()

  if (!Array.isArray(categories)) return <></>
  return (
    <Dropdown
      label={t.UI.categorySelector.title}
      invert={contrast}
      required
      items={categories.map((c) => ({ label: c.name, value: c.uuid }))}
      value={value}
      onChange={onChange}
    />
  )
}

export default CategorySelector
