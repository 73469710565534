import Form from '../UI/Form/Form'
import Layout from '../UI/Layout/Layout'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { auth } from '@think-internet/zeus-frontend-package'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import props from '../../redux/props'
import { Level } from '../../types/Auth'
import Button from '../UI/Button/Button'
import Input from '../UI/Input/Input'
import useToast from '../hooks/useToast'
import logoBlack from '../../assets/image/logo/white_black.png'

const Login = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [mail, setMail] = useState('')
  const [password, setPassword] = useState('')
  const cookies = new Cookies()
  const navigate = useNavigate()
  const toast = useToast()

  const submit = async () => {
    const token = await auth.login(mail, password)

    if (token) {
      cookies.set('token', token, { path: '/' })

      const accountLink = await auth.resolveToken(token)

      if (accountLink) {
        if (accountLink.level === Level.ADMIN) {
          navigate('/admin')
        }
      }
    } else {
      toast(t.login.error)
    }
  }

  return (
    <Layout authLevelRequired={false} smallHeader disableDynamicHeightHeader>
      <div className="flex h-screen justify-center">
        <Form onSubmit={submit} className="p-10 w-1/4 mx-auto">
          <div>
            <img src={logoBlack} alt={t.projectName} className="w-1/2 mx-auto" />
          </div>
          <div className="font-bold text-lg my-5">{t.login.headline}</div>
          <div className="flex gap-3 flex-col items-end">
            <Input placeholder={t.generic.mail} onChange={setMail} value={mail} type="email" required={true} />
            <Input placeholder={t.generic.password} onChange={setPassword} value={password} required={true} type="password" />
          </div>
          <div className="flex justify-center my-5">
            <Button type="submit" text={t.login.cta} />
          </div>
        </Form>
      </div>
    </Layout>
  )
}

export default Login
