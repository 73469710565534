import Container from '../UI/Container/Container'
import Layout from '../UI/Layout/Layout'
import Hero from './Hero/Hero'
import './style.scss'

const Imprint = () => {
  return (
    <Layout authLevelRequired={false}>
      <Hero />
      <Container className="legal-document pb-10">
        <h1>Impressum</h1>

        <p>
          Event Paradies Nimaro GmbH
          <br />
          Eibenweg 23
          <br />
          15827 Blankenfelde-Mahlow
        </p>

        <p>
          Handelsregister: HRB 39425
          <br />
          Registergericht: Amtsgericht Potsdam
        </p>

        <p>
          <strong>Vertreten durch:</strong>
          <br />
          Robin Sebastian Raue
          <br />
          Marco Gattulli
          <br />
          Niclas Siekierka
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: +49 155 611 020 74
          <br />
          E-Mail: info@event-paradies.de
        </p>

        <h2>Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:
          <br />
          DE368817920
        </p>

        <h2>Redaktionell verantwortlich</h2>
        <p>
          Robin Sebastian Raue
          <br />
          Marco Gattulli
          <br />
          Niclas Siekierka
        </p>

        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{' '}
          <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
            https://ec.europa.eu/consumers/odr/
          </a>
          .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
      </Container>
    </Layout>
  )
}

export default Imprint
