import Form from '../../UI/Form/Form'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Input from '../../UI/Input/Input'
import { DeliveryMethod, PersonalData, PreferredContact } from '../types'
import { useState } from 'react'
import Button from '../../UI/Button/Button'
import useCart from '../useCart'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import useToast from '../../hooks/useToast'
import Textarea from '../../UI/Textarea/Textarea'
import DateRangePicker from '../../UI/DateRangePicker/DateRangePicker'
import logo from '../../../assets/image/logo/white.png'
import RadioGroup from '../../UI/RadioGroup/RadioGroup'

type Props = {
  success: boolean
  successCallback: () => void
}

const InputWrapper = ({ children }) => {
  return <div className="flex gap-3">{children}</div>
}

const UserDataForm: React.FC<Props> = ({ success, successCallback }) => {
  const toast = useToast()
  const submitRequest = functional.use(Routes.SUBMIT_REQUEST)
  const t = useSelector((s) => s[props.TRANSLATION])
  const { cart, clear } = useCart()
  const [data, setData] = useState<PersonalData>({
    firstname: '',
    lastname: '',
    streetAndHousenumber: '',
    postcode: '',
    city: '',
    mail: '',
    phone: '',
    text: '',
    dateRange: null,
    preferredContact: PreferredContact.PHONE,
    deliveryMethod: DeliveryMethod.DELIVERY,
  })

  const submit = async () => {
    const response = await submitRequest({ cartUUID: cart.uuid, personalData: data })
    if (response) {
      successCallback()
      clear()
    } else {
      toast(t.cart.checkout.error)
    }
  }

  const set = (key: keyof PersonalData) => (value: any) => {
    setData((prev) => ({ ...prev, [key]: value }))
  }

  if (success) {
    return (
      <div className="absolute top-0 left-0 w-full p-5 h-full md:px-10 flex items-center justify-center flex-col gap-5 backdrop-blur-sm">
        <img src={logo} alt="Event Paradies" className="h-1/3" />
        <div className="md:w-2/3 text-center font-bold text-xl md:text-2xl drop-shadow-md text-white">{t.cart.checkout.success}</div>
      </div>
    )
  }
  return (
    <Form
      onSubmit={submit}
      className="md:shadow-md p-0 pb-20 md:py-5 md:mt-0 h-fit md:h-full min-h-fit md:px-10 md:bg-graySecondary md:bg-opacity-10 md:overflow-y-auto md:overflow-x-hidden flex flex-col justify-center"
    >
      <div className="font-semibold text-gray-900 text-xl">{t.cart.checkout.title}</div>
      <p className="mt-1 text-sm text-gray-600">{t.cart.checkout.hint}</p>
      <div className="mt-5 flex flex-col gap-2">
        <DateRangePicker required label={t.cart.checkout.dateRange} value={data.dateRange} onChange={set('dateRange')} />
        <InputWrapper>
          <Input required label={t.generic.firstname} value={data.firstname} onChange={set('firstname')} />
          <Input required label={t.generic.lastname} value={data.lastname} onChange={set('lastname')} />
        </InputWrapper>
        <Input required type="email" label={t.generic.mail} value={data.mail} onChange={set('mail')} />
        <InputWrapper>
          <Input required label={t.generic.street} value={data.streetAndHousenumber} onChange={set('streetAndHousenumber')} />
          <Input required label={t.generic.postcode} value={data.postcode} onChange={set('postcode')} />
        </InputWrapper>
        <Input required label={t.generic.city} value={data.city} onChange={set('city')} />
        <Input required label={t.generic.phone} value={data.phone} onChange={set('phone')} />
        <RadioGroup
          label={t.cart.checkout.preferredContact.title}
          options={Object.values(PreferredContact).map((o) => ({ label: t.cart.checkout.preferredContact[o], value: o }))}
          value={data.preferredContact}
          onChange={set('preferredContact')}
        />
        <RadioGroup
          label={t.cart.checkout.deliveryMethod.title}
          options={Object.values(DeliveryMethod).map((o) => ({ label: t.cart.checkout.deliveryMethod[o], value: o }))}
          value={data.deliveryMethod}
          onChange={set('deliveryMethod')}
        />
        <Textarea label={t.cart.checkout.text} value={data.text} onChange={set('text')} />
      </div>
      <div className="flex justify-end mt-5">
        <Button type="submit" contrast text={t.cart.checkout.submit} />
      </div>
    </Form>
  )
}

export default UserDataForm
