import { useCallback, useEffect } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import { Category } from '../../types/Category'
import props from '../../redux/props'
import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../redux/action/local'

type Pull = () => Promise<void>
type Query = {
  uuid?: string
}

const useLoadCategories = (query: Query = {}): [Category[] | null, Pull] => {
  const categoryFeaiture = crud.use(props.CATEGORY)
  const categories = useSelector((s) => s[props.CATEGORY])
  const dispatch = useDispatch()

  const pull = useCallback(async () => {
    const categories = await categoryFeaiture.list(query)
    if (categories) {
      dispatch(setLocal(props.CATEGORY, categories))
    }
  }, [categoryFeaiture, query, dispatch])

  useEffect(() => {
    if (!categories) pull()
  }, [categories, pull])

  return [categories, pull]
}

export default useLoadCategories
