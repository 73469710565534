import { Product } from '../../types/Product'
import Card from './Card'

type Props = {
  items: Product[]
}

const Products: React.FC<Props> = ({ items }) => {
  return (
    <div className="flex flex-col md:flex-row flex-wrap gap-[75px] mt-5">
      {items.map((product, i) => (
        <Card key={i} product={product} />
      ))}
    </div>
  )
}

export default Products
