import { useState } from 'react'
import Input from '../../../UI/Input/Input'
import { Bundle } from '../../../../types/Bundle'
import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type as ToastType } from '../../../hooks/useToast'
import Button, { Type } from '../../../UI/Button/Button'
import SlideIn from '../../../UI/SlideIn/SlideIn'
import { PlusIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
import Tooltip from '../../../UI/Tooltip/Tooltip'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'

type Props = {
  initData?: Bundle
  pullList?: () => void
}

const Upsert: React.FC<Props> = ({ initData, pullList }) => {
  const [data, setData] = useState<Bundle>(initData || {})
  const t = useSelector((s) => s[props.TRANSLATION])
  const bundleFeature = crud.use(props.BUNDLE)
  const toast = useToast()
  const navigate = useNavigate()

  const set = (key: keyof Bundle) => (value: any) => setData({ ...data, [key]: value })

  const save = async (): Promise<boolean> => {
    const bundle = await bundleFeature.upsert(data)
    if (bundle) {
      if (!!initData) {
        pullList()
        toast(t.bundle.upsert.success, ToastType.SUCCESS)
      } else {
        navigate(`/admin/bundle/${bundle.uuid}`)
      }
      return true
    } else {
      toast(t.bundle.upsert.error)
      return false
    }
  }

  const remove = async () => {
    await bundleFeature.remove({ uuid: data.uuid })
    pullList()
  }

  return (
    <SlideIn
      title={t.bundle.upsert.title[data.uuid ? 'update' : 'create']}
      formCallback={save}
      removeCallback={initData && remove}
      removeItemName={initData && data.name}
      Trigger={
        initData ? (
          <div>
            <Tooltip text={t.generic.settings}>
              <Cog6ToothIcon className="w-6 stroke-black" />
            </Tooltip>
          </div>
        ) : (
          <div className="">
            <PlusIcon className="w-5 cursor-pointer" />
          </div>
        )
      }
    >
      <div className="flex flex-col gap-3">
        <Input label={t.bundle.attributes.name} value={data.name} onChange={set('name')} required />
        <Button contrast className="ml-auto" type={Type.SUBMIT} text={t.generic.save} />
      </div>
    </SlideIn>
  )
}

export default Upsert
