import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { ArrowRightIcon, CalendarDaysIcon, ShoppingCartIcon, TruckIcon } from '@heroicons/react/24/solid'

const OrderOverview: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const Item = ({ Icon, itemContent }) => {
    return (
      <div className="flex flex-col items-center gap-4 flex-1 shrink-0 w-10/12 md:w-full mx-auto">
        <div className="w-full rounded-lg border-[3px] border-gold flex gap-5 py-3 px-5 justify-between">
          <div className="text-lg">{itemContent.title}</div>
          <ArrowRightIcon className="w-8" />
          <Icon className="w-8" />
        </div>
        <div className="md:text-lg">{itemContent.text}</div>
      </div>
    )
  }

  return (
    <div className="mt-20 md:mt-36 mb-20">
      <div className="text-xl text-gold text-center">{t.product.orderOverview.preTitle}</div>
      <div className="leading-[3.5rem] font-bold text-3xl text-center">{t.product.orderOverview.title}</div>
      <div className="flex flex-col md:flex-row gap-10 mt-5">
        <Item Icon={ShoppingCartIcon} itemContent={t.product.orderOverview.request} />
        <Item Icon={CalendarDaysIcon} itemContent={t.product.orderOverview.book} />
        <Item Icon={TruckIcon} itemContent={t.product.orderOverview.delivery} />
      </div>
    </div>
  )
}

export default OrderOverview
