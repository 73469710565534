import Toggle from './Toggel/Toggle'
import Items from './Items/Items'
import Cart from '../../../../Cart/Cart'

const Navigation: React.FC<{ small: boolean }> = ({ small = false }) => {
  return (
    <div>
      <div className="flex justify-around gap-5 items-center pr-3 lg:hidden">
        <Toggle small={small} />
        <Cart />
      </div>
      <div className="hidden lg:flex justify-around gap-8 items-center">
        <Items small={small} />
      </div>
    </div>
  )
}

export default Navigation
