import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import LargeContainer from '../../../UI/Container/LargeContainer'
import useLoadProducts from '../../../hooks/useLoadProducts'
import { Product } from '../../../../types/Product'
import SimpleList from '../../../UI/SimpleList/SimpleList'
import Item from './Item'
import Create from '../Create/Create'
import HeadlineSplit from '../../../UI/Headline/Split'
// import InitProductDataImport from './InitProductDataImport'

const List = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [products, pull] = useLoadProducts()

  const filter = (search: string) => (item: Product) => {
    return item.name.toLowerCase().includes(search.toLowerCase())
  }

  const Header = () => (
    <div className="cursor-pointer flex w-full items-center gap-2">
      <div className="w-full font-bold">{t.product.attributes.name}</div>
      <div className="w-full font-bold">{t.admin.product.list.hasImages}</div>
    </div>
  )

  return (
    <LargeContainer>
      <div className="flex flex-col gap-1 pt-2 pb-10 mt-10">
        <HeadlineSplit suffix={t.product.list.titleSplit.suffix} subline={t.product.list.titleSplit.subline} />
        {/* <div className="mt-5 p-2 shadow-sm bg-lightGray md:w-1/2 lg:w-1/3 flex flex-col gap-2">
          <div className="font-bold">{t.admin.product.initProductDataImport.title}</div>
          <div className="text-sm ">{t.admin.product.initProductDataImport.text}</div>
          <InitProductDataImport callback={pull} />
        </div> */}
        <SimpleList data={products} filter={filter} Component={Item} pull={pull} Upsert={Create} Header={Header} />
      </div>
    </LargeContainer>
  )
}

export default List
