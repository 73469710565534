import { crud } from '@think-internet/zeus-frontend-package'

const props = {
  ...crud.Features,

  // admin features
  PRODUCT: 'PRODUCT',
  CATEGORY: 'CATEGORY',
  BUNDLE: 'BUNDLE',

  // user features
  CART: 'CART',
}

export default props
