import { useCallback, useEffect } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import { Bundle } from '../../types/Bundle'
import props from '../../redux/props'
import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../redux/action/local'

type Pull = () => Promise<void>
type Query = {
  uuid?: string
}

const useLoadBundles = (query: Query = {}): [Bundle[] | null, Pull] => {
  const bundleFeature = crud.use(props.BUNDLE)
  const bundles = useSelector((s) => s[props.BUNDLE])
  const dispatch = useDispatch()

  const pull = useCallback(async () => {
    const bundles = await bundleFeature.list(query)
    if (bundles) {
      dispatch(setLocal(props.BUNDLE, bundles))
    }
  }, [bundleFeature, query, dispatch])

  useEffect(() => {
    if (!bundles) pull()
  }, [bundles, pull])

  return [bundles, pull]
}

export default useLoadBundles
