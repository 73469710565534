import Input, { BaseInput } from './Input'
import CurrencyFormat from 'react-currency-format'

const Currency: React.FC<BaseInput & { suffix?: string }> = ({ value, onChange, suffix = ' €', ...params }) => {
  return (
    <CurrencyFormat
      customInput={Input}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale={true}
      suffix={suffix}
      value={value || ''}
      onValueChange={(v) => onChange(v.floatValue)}
      returnRawChangeEvent
      {...params}
      type="text"
    />
  )
}

export default Currency
