import { Cart } from '../components/Cart/types'
import { translation, langKey } from '../translations/de'

export type DefaultState = {
  TRANSLATION: { [key: string]: any }
  CURRENTLANG: String
  CART: Cart
}

const defaultState: DefaultState = {
  TRANSLATION: translation,
  CURRENTLANG: langKey,
  CART: null,
}

export default defaultState
