import { TrashIcon } from '@heroicons/react/24/outline'
import useCart from './useCart'
import { formatEUR, getProductLink } from '../../utility'
import Image from '../UI/Image/Image'
import { getProduct } from './helper'
import useLoadProducts from '../hooks/useLoadProducts'
import { ProductCartItem } from './types'
import { Product } from '../../types/Product'

const ProductList: React.FC<{ productList: ProductCartItem[]; isCartMode?: boolean; isBundleMode?: boolean }> = ({
  productList,
  isCartMode = false,
  isBundleMode = false,
}) => {
  const { cart, remove } = useCart()
  const [products] = useLoadProducts()

  const getPackage = (product: Product, packageUUID?: string) => {
    if (!packageUUID) return null
    const p = product.price.packages.find((p) => p.uuid === packageUUID)
    if (!p) return null
    return p
  }

  const getPrice = (product: Product, packageUUID?: string) => {
    const p = getPackage(product, packageUUID)
    if (p) return formatEUR(p.price)
    return formatEUR(product.price.startingPrice)
  }

  if (!products || !cart) return null
  return (
    <ul className={isBundleMode ? 'flex flex-col gap-10' : ''}>
      {productList
        .filter((p) => !!getProduct(products, p.uuid))
        .map((p) => ({ uuid: p.uuid, product: getProduct(products, p.uuid), packageUUID: p.packageUUID }))
        .map((p) => (
          <li
            key={p.uuid}
            className={`flex flex-col md:flex-row ${
              isBundleMode ? 'bg-white p-6 shadow-md rounded-md' : 'border-b border-black border-opacity-30 py-6'
            }`}
          >
            <a
              href={getProductLink(p.product)}
              className={`${isBundleMode ? 'md:w-40' : 'md:w-24'} aspect-video md:aspect-square flex-shrink-0 overflow-hidden rounded-md`}
            >
              <Image key={p.product.uuid} src={p.product.imageKeyList[0]} className="w-full h-full object-cover" />
            </a>

            <div className="mt-3 md:mt-0 md:ml-4 flex flex-1 flex-col">
              <div>
                <div className="flex justify-between font-medium text-gray-900">
                  <h3>
                    <a href={getProductLink(p.product)} className={`font-bold ${isBundleMode && 'text-md'}`}>
                      {p.product.name}
                    </a>
                  </h3>
                  <p className="ml-4">{getPrice(p.product, p.packageUUID)}</p>
                </div>
                {getPackage(p.product, p.packageUUID) && <div>{getPackage(p.product, p.packageUUID).name}</div>}
              </div>
              {isBundleMode && <div className="whitespace-pre-wrap">{p.product.description}</div>}
              {!!isCartMode && (
                <div className="flex flex-1 items-end justify-end text-sm">
                  <div className="flex">
                    <button onClick={() => remove(p.product.uuid)} type="button" className="font-medium text-indigo-600 hover:text-indigo-500">
                      <TrashIcon className="w-5 h-5 hover:stroke-red" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </li>
        ))}
    </ul>
  )
}

export default ProductList
