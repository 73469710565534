import { useCallback, useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { Product } from '../../../types/Product'

const useGetSuggestions = (productUUID: string): Product[] => {
  const getSuggestions = functional.use(Routes.GET_PRODUCT_SUGGESTIONS)
  const [suggestions, setSuggestions] = useState<Product[]>(null)

  const pull = useCallback(async () => {
    const products = await getSuggestions({ productUUID })
    if (products) {
      setSuggestions(products)
    }
  }, [getSuggestions, productUUID])

  useEffect(() => {
    if (!suggestions) pull()
  }, [suggestions, pull])

  return suggestions
}

export default useGetSuggestions
