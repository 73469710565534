import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import LargeContainer from '../../../UI/Container/LargeContainer'
import useLoadCategories from '../../../hooks/useLoadCategories'
import { Category } from '../../../../types/Category'
import SimpleList from '../../../UI/SimpleList/SimpleList'
import Item from './Item'
import Upsert from '../Upsert/Upsert'
import HeadlineSplit from '../../../UI/Headline/Split'

const List = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [categories, pull] = useLoadCategories()

  const filter = (search: string) => (item: Category) => {
    return item.name.toLowerCase().includes(search.toLowerCase())
  }

  const Header = () => (
    <div className="cursor-pointer flex w-full items-center gap-2">
      <div className="w-11/12 font-bold">{t.category.attributes.name}</div>
      <div className="w-1/12"></div>
    </div>
  )

  return (
    <LargeContainer>
      <div className="flex flex-col gap-1 pt-2 pb-10 mt-10">
        <HeadlineSplit suffix={t.category.list.titleSplit.suffix} subline={t.category.list.titleSplit.subline} />
        <SimpleList data={categories} filter={filter} Component={Item} pull={pull} Upsert={Upsert} Header={Header} />
      </div>
    </LargeContainer>
  )
}

export default List
