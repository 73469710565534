import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import Input from '../Input/Input'

type Item = {
  label: string
  value: any
}

type Props = {
  className?: string
  onChange?: (value: any) => void
  value?: any
  label?: string
  items?: Item[]
  required?: boolean
  emptySelection?: string
  showEmptySelection?: boolean
  multiple?: boolean
  invert?: boolean
  disableSearch?: boolean
}

const Dropdown: React.FC<Props> = ({
  className = '',
  onChange,
  value,
  label,
  items = [],
  required = false,
  emptySelection,
  showEmptySelection = false,
  multiple = false,
  invert = false,
  disableSearch = false,
}) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const t = useSelector((s) => s[props.TRANSLATION])

  const toggle = () => setOpen(!open)

  useEffect(() => {
    if (multiple && (value === undefined || !Array.isArray(value))) {
      onChange([])
    }
  }, [value, multiple, onChange])

  const getCurrentValue = () => {
    if (multiple && Array.isArray(value)) {
      const selectedItems = items.filter((item) => value.includes(item.value))
      if (selectedItems.length === 0) {
        return emptySelection || translation.UI.dropdown.emptySelection
      }
      return selectedItems.map((item) => item.label).join(', ')
    } else if (!multiple) {
      const item = items.find((item) => item.value === value)
      return item ? item.label : emptySelection || translation.UI.dropdown.emptySelection
    }
  }

  const set = (item: Item) => () => {
    if (multiple) {
      if (value.includes(item.value)) {
        onChange(value.filter((v: any) => v !== item.value))
      } else {
        onChange([...value, item.value])
      }
    } else {
      onChange(item.value)
      toggle()
    }
  }

  const setFocus = () => {
    if (!open) {
      toggle()
      setSearch('')
    } else {
      toggle()
    }
  }

  const searchFilter = (item: Item) => {
    return item.label.toLowerCase().includes(search.toLowerCase())
  }

  const isSelected = (item: Item) => {
    if (multiple) {
      return value.includes(item.value)
    } else {
      return value === item.value
    }
  }

  const Item = ({ item }: { item: Item }) => (
    <div
      className={`border-t border-lightGray px-3 py-1 text-sm ${
        isSelected(item)
          ? `${invert ? 'bg-white text-gold' : 'bg-gold text-black'}`
          : `${invert ? 'bg-black text-white hover:bg-white hover:text-gray' : 'hover:bg-gold hover:text-black'}`
      } cursor-pointer text-sm`}
      key={item.value}
      onClick={set(item)}
    >
      {item.label}
    </div>
  )

  return (
    <div className={`w-full ${className}`}>
      {label && <label className={invert ? 'text-white text-sm' : 'text-gray text-sm'}>{label}</label>}
      <div className="relative">
        <div
          className={`flex items-center cursor-pointer ${
            invert
              ? 'border rounded-md border-white placeholder-lightGray text-white w-full py-2 px-1 bg-black focus:outline-none focus:shadow-outline'
              : 'border rounded-md border-gold placeholder-gray w-full py-2 px-1 bg-white text-black focus:outline-none focus:shadow-outline'
          } ${className}`}
          onClick={setFocus}
        >
          <div className="flex items-row items-center w-full relative">
            <input type="text" className="opacity-0 absolute bottom-0 h-[1px]" value={value} required={required} onChange={() => {}} />
            <span className={`leading-none w-full ${invert ? 'text-lightGray' : 'text-gray'}`}>{getCurrentValue()}</span>
            {open && <ChevronUpIcon className={`h-4 w-4 ml-2 ${invert ? 'stroke-white' : 'stroke-black'}`} />}
            {!open && <ChevronDownIcon className={`h-4 w-4 ml-2 ${invert ? 'stroke-white' : 'stroke-black'}`} />}
          </div>
        </div>
        {open && (
          <div
            className={`absolute flex flex-col w-full left-0 top-full mt-1 bg-white z-10 border ${
              invert ? 'border-white rounded-md' : 'border-gold rounded-md'
            }`}
          >
            {!disableSearch && (
              <Input
                placeholder={t.UI.dropdown.search}
                onChange={setSearch}
                value={search}
                className="text-sm"
                contentClassName="!py-1 border-none"
                invert={invert}
              />
            )}
            <div className="max-h-40 overflow-x-hidden overflow-y-auto shadow-md">
              {showEmptySelection && (
                <Item
                  item={{
                    label: emptySelection || translation.UI.dropdown.emptySelection,
                    value: undefined,
                  }}
                />
              )}
              {items.filter(searchFilter).map((item) => (
                <Item key={item.value} item={item} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Dropdown
