import styled from 'styled-components'
import LargeContainer from '../../UI/Container/LargeContainer'
import Layout from '../../UI/Layout/Layout'
import ProductList from '../ProductList'
import UserDataForm from './UserDataForm'
import useDidScroll from '../../UI/Layout/Header/useDidScroll'
import { useState } from 'react'
import useCart from '../useCart'
import { formatEUR } from '../../../utility'
import { getTotal } from '../helper'
import useLoadProducts from '../../hooks/useLoadProducts'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import successBG from '../../../assets/image/checkout/success.jpg'

const Checkout = () => {
  const didScroll = useDidScroll()
  const [products] = useLoadProducts()
  const [success, setSuccess] = useState(false)
  const { cart } = useCart()
  const t = useSelector((s) => s[props.TRANSLATION])

  const HeightWrapper = styled.div<{ didScroll: boolean }>`
    height: calc(100vh - ${({ didScroll }) => (didScroll ? '4rem' : '6rem')});
    margin-top: ${({ didScroll }) => (didScroll ? '4rem' : '6rem')};
  `

  const BackgroundWrapper = styled.div<{ success: boolean }>`
    ${({ success }) =>
      success &&
      `
    background-image: url(${successBG});
    `}
  `

  if (!cart) return null
  return (
    <Layout authLevelRequired={false} noFooter>
      <BackgroundWrapper success={success} className={`bg-cover`}>
        <LargeContainer>
          <HeightWrapper className="h-fit md:h-screen" didScroll={didScroll}>
            <div className="flex gap-10 md:min-h-full md:overflow-hidden">
              {!success && (
                <div className="hidden md:block flex-1 overflow-x-hidden px-10 pb-10 pt-5">
                  <ProductList isCartMode productList={cart.productList} />
                  <div className="pt-10 flex justify-between font-medium text-gray-900">
                    <p className="font-bold text-md">{t.cart.preview.minValue}</p>
                    <p className="font-bold text-md">{formatEUR(getTotal(cart, products))}</p>
                  </div>
                  <p className="text-sm mt-0">{t.cart.checkout.totalHint}</p>
                </div>
              )}
              <div className="w-full md:flex-1 flex items-center justify-center">
                <UserDataForm success={success} successCallback={() => setSuccess(true)} />
              </div>
            </div>
          </HeightWrapper>
        </LargeContainer>
      </BackgroundWrapper>
    </Layout>
  )
}

export default Checkout
