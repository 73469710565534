enum Routes {
  // admin routes
  GET_FILE_UPLOAD_PRESIGNED_URL = 'GET_FILE_UPLOAD_PRESIGNED_URL',
  TRIGGER_INITIAL_PRODUCT_DATA_IMPORT = 'TRIGGER_INITIAL_PRODUCT_DATA_IMPORT',
  // user routes
  GET_PRODUCT_SUGGESTIONS = 'GET_PRODUCT_SUGGESTIONS',
  LOAD_CART = 'LOAD_CART',
  SAVE_CART = 'SAVE_CART',
  SUBMIT_REQUEST = 'SUBMIT_REQUEST',
}

export default Routes
