import { Category } from '../../../../types/Category'
import Upsert from '../Upsert/Upsert'

type Props = { data: Category; pullList: () => void }

const Item: React.FC<Props> = ({ data, pullList }) => {
  return (
    <div className="flex w-full items-center gap-2">
      <div className="w-11/12 block">{data.name}</div>
      <div className="w-1/12 flex justify-end">
        <Upsert initData={data} pullList={pullList} />
      </div>
    </div>
  )
}

export default Item
