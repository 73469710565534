import { getUUID } from '../../../utility'

type Option = {
  label: string
  value: string
}

type Props = {
  label?: string
  options: Option[]
  value: string
  onChange: (value: string) => void
  invert?: boolean
  className?: string
}

const RadioGroup: React.FC<Props> = ({ label, options, value, onChange, invert, className = '' }) => {
  const name = getUUID()
  return (
    <div className={`w-full ${className}`}>
      {label && <label className={invert ? 'text-white text-sm' : 'text-gray text-sm'}>{label}</label>}
      <div className="flex flex-col md:flex-row gap-2 md:gap-5 md:items-center mt-1">
        {options.map((option, i) => {
          const id = `${name}-${i}`
          return (
            <div className="inline-flex items-center gap-1">
              <label className="relative flex items-center cursor-pointer" htmlFor={id}>
                <input
                  name={name}
                  type="radio"
                  className="peer h-5 w-5 cursor-pointer appearance-none rounded-full border-2 border-gold checked:border-gold transition-all"
                  id={id}
                  checked={value === option.value}
                  onChange={() => onChange(option.value)}
                />
                <span className="absolute bg-gold w-3 h-3 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-200 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></span>
              </label>
              <label className="text-slate-600 cursor-pointer text-sm" htmlFor={id}>
                {option.label}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RadioGroup
