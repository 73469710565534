import { useCallback, useEffect } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import { Product } from '../../types/Product'
import props from '../../redux/props'
import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../redux/action/local'

type Pull = () => Promise<void>

const useLoadProducts = (categoryUUID?: string): [Product[] | null, Pull] => {
  const productFeature = crud.use(props.PRODUCT)
  const products = useSelector((s) => s[props.PRODUCT])
  const dispatch = useDispatch()

  const pull = useCallback(async () => {
    const products: Product[] = await productFeature.list({})
    if (products) {
      dispatch(setLocal(props.PRODUCT, products))
    }
  }, [productFeature, dispatch])

  useEffect(() => {
    if (!products) pull()
  }, [products, pull])

  if (!products) return [null, pull]
  return !!categoryUUID ? [products.filter((p) => p.categoryUUID === categoryUUID), pull] : [products, pull]
}

export default useLoadProducts
