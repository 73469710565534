import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import logoWhite from '../../../../assets/image/logo/white_black.png'

const Footer = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="bg-white border-t border-gold py-8 flex items-center flex-col gap-3">
      <img src={logoWhite} alt={t.projectName} className="w-20" />
      <div className="flex gap-3">
        {t.footer.legalLinks.map((item, i) => (
          <div className="flex gap-3" key={i}>
            {i !== 0 && <div>|</div>}
            <a href={item.link}>{item.name}</a>
          </div>
        ))}
      </div>
      <div className="font-light">{t.footer.copyright}</div>
    </div>
  )
}

export default Footer
